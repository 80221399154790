export const WORDS = [
  'kanwa',
  'itama',
  'kwama',
  'sinzi',
  'ikawa',
  'akuya',
  'iruta',
  'rimwe',
  'ubuja',
  'aziba',
  'mwiza',
  'akeza',
  'itara',
  'umuti',
  'gasha',
  'amatu',
  'ayawe',
  'gupfa',
  'atera',
  'ikoti',
  'mpene',
  'ukura',
  'araye',
  'ingwa',
  'mbavu',
  'mbere',
  'iyawe',
  'ipine',
  'yitwa',
  'hirya',
  'ufata',
  'ntama',
  'ubona',
  'isaho',
  'hepfo',
  'icayi',
  'imiti',
  'kwiba',
  'aruta',
  'izosi',
  'yanse',
  'nkore',
  'isema',
  'ikiri',
  'nzima',
  'ibiri',
  'itema',
  'isuka',
  'yanje',
  'iwawe',
  'nzoga',
  'ngufi',
  'vyawe',
  'urugi',
  'ngaho',
  'irasa',
  'iwabo',
  'kwota',
  'ihura',
  'buryo',
  'rundi',
  'inswa',
  'kanje',
  'isoni',
  'icupa',
  'kumwa',
  'abana',
  'ihasa',
  'ijoro',
  'nkawe',
  'ayita',
  'atoye',
  'amazi',
  'ubuki',
  'ntaba',
  'itabi',
  'ivoka',
  'ntiha',
  'nivyo',
  'icumu',
  'akava',
  'ifuro',
  'indya',
  'icaha',
  'itiro',
  'imbwa',
  'wanka',
  'ihise',
  'nzero',
  'kwiga',
  'igito',
  'amira',
  'ivubi',
  'ntare',
  'abera',
  'ukaba',
  'isoko',
  'inuma',
  'abiri',
  'akawe',
  'indwi',
  'iraba',
  'ntata',
  'ubura',
  'ipera',
  'mpore',
  'genda',
  'iziko',
  'ihera',
  'umuha',
  'ijana',
  'wumva',
  'isoya',
  'umufa',
  'ahari',
  'canke',
  'mpeze',
  'umubu',
  'ikaze',
  'ihora',
  'kwoza',
  'ubuze',
  'ibara',
  'nkono',
  'agati',
  'ibabi',
  'abiwe',
  'inkwi',
  'isake',
  'shima',
  'uhawe',
  'nikwo',
  'isaha',
  'ibati',
  'ugana',
  'bweze',
  'ugaye',
  'jisho',
  'uteze',
  'nyene',
  'usiga',
  'ihaka',
  'ukuri',
  'agatu',
  'arira',
  'acira',
  'yenga',
  'ugiye',
  'ndabe',
  'igiti',
  'nkoko',
  'ninde',
  'nzira',
  'akari',
  'ugata',
  'ndeka',
  'vyose',
  'ibuye',
  'nitwa',
  'gukwa',
  'ugowe',
  'canje',
  'ijeri',
  'nopfa',
  'ufise',
  'nyoko',
  'rwose',
  'mbibe',
  'kurya',
  'ivuze',
  'uwica',
  'namwe',
  'icumi',
  'ivuga',
  'yimba',
  'ntaja',
  'bwiza',
  'umuce',
  'amufe',
  'senga',
  'idubu',
  'rwama',
  'imana',
  'ryera',
  'karya',
  'igeze',
  'ugira',
  'abami',
  'uwiwe',
  'icobo',
  'ntaco',
  'iwacu',
  'ugaca',
  'ipasi',
  'imibu',
  'nteka',
  'sikwo',
  'ahita',
  'itera',
  'irobe',
  'ryiza',
  'gusya',
  'ifuni',
  'wanje',
  'bwira',
  'amagi',
  'bwari',
  'umuto',
  'igira',
  'igufa',
  'ajana',
  'kandi',
  'ngohe',
  'burya',
  'twire',
  'muntu',
  'uriko',
  'urata',
  'azira',
  'nanse',
  'nyika',
  'ukora',
  'ubusa',
  'mwene',
  'sokwe',
  'inoti',
  'gusha',
  'mwezi',
  'zanje',
  'ikaba',
  'isazi',
  'ubana',
  'izuba',
  'umuco',
  'bwiwe',
  'araza',
  'akazi',
  'iriye',
  'hinge',
  'amano',
  'ukuba',
  'akiri',
  'uruvo',
  'ivomo',
  'mbega',
  'wundi',
  'nyuma',
  'amaso',
  'igabo',
  'imizi',
  'urugo',
  'itora',
  'ubute',
  'avuna',
  'agaba',
  'ntuva',
  'amase',
  'itazi',
  'uguha',
  'imeza',
  'utera',
  'nanje',
  'ukoze',
  'imari',
  'wopfa',
  'akaje',
  'ugaya',
  'ahora',
  'agira',
  'ndazi',
  'nyama',
  'ntuta',
  'kitwa',
  'unuka',
  'bwami',
  'urume',
  'ingwe',
  'izuru',
  'rindi',
  'kamwe',
  'utari',
  'amara',
  'acika',
  'nibwo',
  'uwawe',
  'ijuru',
  'ndiga',
  'unywa',
  'isato',
  'ihaha',
  'mfise',
  'ikibi',
  'ikaye',
  'ibura',
  'rwacu',
  'ngoro',
  'ahaba',
  'abira',
  'asiga',
  'nyina',
  'amata',
  'sibwo',
  'uruja',
  'avuga',
  'utuka',
  'niryo',
  'hanze',
  'utazi',
  'anuka',
  'inama',
]
