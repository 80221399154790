export const MONTHS = [
  'Nzero',
  'Ruhuhuma',
  'Ntwarante',
  'Ndamukiza',
  'Rusama',
  'Ruheshi',
  'Mukakaro',
  'Myandagaro',
  'Nyakanga',
  'Gitugutu',
  'Munyonyo',
  'Kigarama',
]
