import { StatItem } from './StatItem'
import { CurrentDate } from './CurrentDate'
import { GameStats } from '../../lib/localStorage'
import {
  BEST_STREAK_TEXT,
  CURRENT_STREAK_TEXT,
  SUCCESS_RATE_TEXT,
  TOTAL_TRIES_TEXT,
} from '../../constants/strings'

type Props = {
  gameStats: GameStats
}

export const StatBar = ({ gameStats }: Props) => {
  return (
    <>
    <div className="flex justify-center my-2">
      <CurrentDate />
    </div>
    <div className="my-2 flex justify-center">
      <StatItem label={TOTAL_TRIES_TEXT} value={gameStats.totalGames} />
      <StatItem label={SUCCESS_RATE_TEXT} value={`${gameStats.successRate}%`} />
      <StatItem label={CURRENT_STREAK_TEXT} value={gameStats.currentStreak} />
      <StatItem label={BEST_STREAK_TEXT} value={gameStats.bestStreak} />
    </div>
    </>
  )
}
