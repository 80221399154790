import { startOfToday, startOfYesterday } from 'date-fns'

import { MONTHS } from '../constants/months'

export const getToday = () => startOfToday()
export const getYesterday = () => startOfYesterday()

type KirundiDatePros = {
  date: Date
  withDate?: boolean
}

export const getKirundiDate = ({ date, withDate = true }: KirundiDatePros) => {
  const day = date.getDate()
  const month = MONTHS[date.getMonth()]
  const year = date.getFullYear()
  if (withDate) {
    return `${day} ${month} ${year}`
  }
  return `${month} ${year}`
}
