export type ProverbKey = keyof typeof PROVERBS

export const PROVERBS = {
  jisho: [
    'Ababwiriza benshi barashisha mu jisho.',
    'Igiti cagukoze mu jisho cama gihiritse.',
  ],
  imbwa: [
    'Abahigi benshi bayobeza imbwa.',
    'Ico imbwa yanse ushira aho iraba.',
    'Imbwa ibura se ntibura shebuja.',
    'Imbwa ntigutwengera iba ishaka kukurya.',
    'Imbwa yarahevye umwino irakura.',
    'Imbwa yarihuse ivyara ibihumye.',
    "Imbwa y'umwansi ntikurira amavyi y'umwana.",
    'Uhenda imbwa umuziha ukaba wihenze umuhondo.',
    'Umuntu arasonza akibuka ubwo yahaye imbwa.',
    "Umuti w'imbwa wahwa ku rugo.",
    "Uramba cane ukarinda uruvyino rw'imbwa.",
    "Urya nk'inka ugapfa nk'imbwa.",
    "Usangira ibanga n'imbwa ikarikwiza ibisaka.",
    'Uhisha mu nda imbwa ntimwiba.',
    'Ukubarira akubesha agira ngo Naguhereye imbwa umuziha irawanka.',
    'Ukubitira imbwa gusutama arazimara.',
    'Uronse imbwa ayiharaye ayiharira iziko.',
    "Imbwa y'umworo yiba igwaye.",
  ],
  gasha: ["Agasozi k'intahanurwa gasha abagabo babona"],
  agati: [
    "Agati gateretswe n'Imana ntigahenurwa n'umuyaga.",
    'Utazi agati akabishako.',
  ],
  imana: [
    "Agati gateretswe n'Imana ntigahenurwa n'umuyaga.",
    'Batanga inka ntibatanga Imana.',
    'Buseye Imana bukarika abagabo.',
    'Hagutera icumu nyene Imana worimutiza.',
    "Hakwishima woshimwa n'Imana.",
    'Ibitagira inama ntibigira Imana.',
    'Ibigiye inama bigira Imana.',
    'Ico Imana ikuyeko amaboko ntigisongorerwa.',
    'Imana igira ico imaniyeko.',
    'Imana iraguha ntimugura, iyo muguze iraguhenda.',
    'Imana irahisha umuntu mu mbibe agakira.',
    'Imana iruta imanga.',
    'Imana isumba ingabo.',
    "Nyen'Imana avumbikisha irenga.",
    'Umwansi agucira icobo, Imana ikagucira icanzo.',
    "Uririra Imana y'uwundi ikakurwaza amahere.",
    'Urupfu ntirumarira Imana.',
    'Usabira Imana umugisha ku ziko ikagusiga umunyota.',
    "Uhabwa n'uwo yahaye aba agira Imana.",
  ],
  agatu: [
    'Agatu gatekewe gatera amakenga.',
    "Agatu k'amagara ntigatwarwa n'uwufyina.",
  ],
  ahari: [
    'Ahari abagabo ntihagwa ibara.',
    'Ahari umugabo hashira umugayo.',
    "Ahari ubumwe urusato rw'imbaragasa rwifuka batanu.",
  ],
  ibara: ['Ahari abagabo ntihagwa ibara.', 'Inkuru ibara uwayibonye.'],
  nyene: [
    "Aho kwicara ku mutogoto w'inkono wokwicara kw'ijambo rya nyene yo.",
    'Hagutera icumu nyene Imana worimutiza.',
    'Umutego mubi ufata nyene wo.',
  ],
  urugi: [
    "Aho wabukaranga nari musi y'urugi.",
    'Nta wutiza urugi imfyisi zateye.',
  ],
  atoye: ['Aho umukecuru atoye uruhindu ahita mu ruganda.'],
  karya: ['Akabigira kabizi karya imboga karitse.'],
  akaje: ['Akaje ntikazira umwe.'],
  kamwe: ['Akantu ntigakorwa na kamwe.'],
  kurya: [
    'Akanyoni kaja kurya akandi kagira ngo unuka urunkeke.',
    "Intobotobo n'intire mu kurya barasobanura.",
    'Kurya incuti siwo mugisha.',
    'Nkunda kurya yariye igifyera kimumena itama.',
    'Numvirize nikwo kurya.',
  ],
  unuka: ['Akanyoni kaja kurya akandi kagira ngo unuka urunkeke.'],
  bweze: ['Akanyoni katagurutse ntikamenya iyo bweze.'],
  akari: [
    'Akari mu mpene niko kari mu ntama.',
    "Akari mu nda y'umugabo gaserurwa n'akari mu nda y'umubindi.",
    "Akari mu nda y'umugabo gaserurwa n'akari mu nda y'umubindi.",
  ],
  mpene: ['Akari mu mpene niko kari mu ntama.'],
  ihise: ["Ak'imuhana kaza imvura ihise"],
  rimwe: [
    'Amajambo menshi mabi arutwa na rimwe ryiza.',
    'Inkuba ikuba rimwe.',
    'Umugabo apfa rimwe ntapfa kabiri.',
    'Utengata amatu abiri ugata rimwe.',
    'Zisohokera rimwe zigasabana amahonda.',
  ],
  ryiza: ['Amajambo menshi mabi arutwa na rimwe ryiza.'],
  aziba: ['Amaronko menshi aziba amatwi.'],
  wundi: ['Amarushwa ari ku wundi ntakubuza itiro.'],
  amase: ['Amase yakera ntahoma urutaro.'],
  abiri: [
    'Amashenyo abiri ntahurira ku kimenge.',
    'Utengata amatu abiri ugata rimwe.',
  ],
  araye: ['Amasinde ntagwa imbuto aba araye.'],
  amaso: [
    "Amaso y'ibikere ntabuza abavomyi.",
    "Amaso y'uwundi ntakurabira umugani.",
    "Intabwirwa ibwirwa n'uko amaso atukuye.",
    "Ivy'i bunini bihaza amaso.",
    "Ivy'isi bihaza amaso.",
  ],
  amazi: [
    'Amazi arashuha ntiyibagira i bumbeho.',
    'Amazi masabano ntamara imvyiro.',
    "Ugumiwe n'amenyo ahaganywa n'amazi.",
  ],
  ayawe: ['Amenyo aragutwenga ari ayawe.', 'Amenyo aragutwenga ari ayawe.'],
  atera: ['Amenyo mabi atera inayo agatwengo.'],
  inayo: ['Amenyo mabi atera inayo agatwengo.'],
  ubona: [
    'Barima ubona bakarya ubona.',
    'Barima ubona bakarya ubona.',
    'Umuvyeyi atari so agaba ubona, agatekera ubona.',
    'Umuvyeyi atari so agaba ubona, agatekera ubona.',
  ],
  imisi: [
    'Bukirikiri bwantunze umworo uwo imisi yubutseko ntawumuha nirwere.',
    'Imisi irasa ntingana.',
  ],
  ntare: ['Gira Ntare na Ruhiga ntikiramutswa abami babiri kiba gipfuye.'],
  abami: ['Gira Ntare na Ruhiga ntikiramutswa abami babiri kiba gipfuye.'],
  sikwo: [
    'Guseka umusore sikwo kumuhanura.',
    'Gusoba ingenda sikwo gushika amahoro.',
    'Kwirirwaayo sikwo gukora.',
  ],
  icumu: [
    'Hagutera icumu nyene Imana worimutiza.',
    "Nta wutera icumu mw'icumi",
  ],
  nopfa: ['Hakuba twopfa woba nopfa.'],
  imizi: ["Ibisangiye imizi bisangira n'ukwuma"],
  inama: [
    'Ibitagira inama ntibigira Imana.',
    'Ibigiye inama bigira Imana.',
    'Umutwe umwe ntiwigira inama.',
  ],
  ibuye: [
    'Ibuye riserutse ntirihongora isuka.',
    'Nta wutera ibuye aho yateretse igisabo.',
  ],
  isuka: ['Ibuye riserutse ntirihongora isuka.', "Nta wutiza isuka mw'irima"],
  yanse: ['Ico imbwa yanse ushira aho iraba.'],
  iraba: ['Ico imbwa yanse ushira aho iraba.'],
  uhawe: ["Ico uhawe n'ugutora ucakwa n'ugutakaza"],
  utari: ['Ico utari buhe umwana ntukimwereka.'],
  burye: ['Ico uri burye ntimwiruka.'],
  utazi: [
    'Ico utazi kikunywera inka.',
    "Ico utazi ucerekwa n'ukuraba.",
    'Inyongori muhura yisize utazi yo yayakuye.',
    'Utazi agakura agaba umutavu.',
    'Utazi agati akabishako.',
    'Utazi akaraye araza ifu.',
    'Utazi ayavugwa ashikira gutwenga.',
    'Utazi ibizoba yitegurira umushitsi.',
    "Utazi kwuzura, agira ngw'ese ayo nikoreye.",
    'Utazi umwansi asiga umubiri.',
  ],
  igabo: ['Igabo rigira uwuryubakiye.'],
  igeze: ["Igeze kure igerwa ihembe n'impene"],
  igiti: [
    'Igiti cagukoze mu jisho cama gihiritse.',
    "Inkende yicirwa musi y'igiti yarigeze kucurira.",
  ],
  igito: ['Igito gitabwa iwabo.'],
  ihera: [
    'Ija kurisha ihera ku nama.',
    'Inzoga ihera ku mwezi ntihera ku muvumvyi.',
  ],
  ijana: ['Ijana ntiryanka mu rindi.', "Ijana riragurwa rirutwa n'imwe itashe"],
  rindi: ['Ijana ntiryanka mu rindi.'],
  ikaze: ['Ikaze risumba umutumiro.'],
  igira: ['Imana igira ico imaniyeko.'],
  mbibe: ['Imana irahisha umuntu mu mbibe agakira.'],
  iruta: [
    'Imana iruta imanga.',
    'Induru yaguhiriye iruta imirembe yagupfubiye',
  ],
  ibura: ['Imbwa ibura se ntibura shebuja.'],
  nkono: ['Imboga mbi ntiziva mu nkono.'],
  gusha: [
    "Imikanure y'urukwavu ntiyabujije igisaka gusha.",
    'Ubuja gusha buratagata.',
  ],
  irasa: ['Imisi irasa ntingana.'],
  mwene: ["Impfizi y'indwanyi iterekerwa mwene se"],
  ivuga: [
    'Imvuzi ya menshi ivuga urwishe nyina.',
    'Inuma ntigira ngo Hugugu iba ivuga ngo Ha uguha.',
  ],
  nyina: [
    'Imvuzi ya menshi ivuga urwishe nyina.',
    'Inyana imenywa na nyina mu mwiza.',
    'Inyana ntiyoberwa nyina mu mwiza.',
    'Usanze nyina ashaje agira ngo se yataye inka.',
  ],
  ntaja: ['Inamarara ntaja mu nyama.'],
  nyama: ['Inamarara ntaja mu nyama.'],
  indya: ['Inda ndende ihumira indya., Indya mbi n indya nke.'],
  ahita: ['Aho umukecuru atoye uruhindu ahita mu ruganda.'],
  itazi: ['Ingumba itazi ikibi irigata ishenyo.'],
  ikibi: [
    'Ingumba itazi ikibi irigata ishenyo.',
    "Inkozi y'ikibi ihora igishima isanze cicaye mu nzira.",
  ],
  iriye: ['Inkende itsirikirwa kuco iriye.', 'Intore itora iriye.'],
  amagi: ['Inkoko irapfa amagi akabora.'],
  urugo: [
    'Inkoni ikubiswe mukeba uyirenza urugo.',
    'Iyengeyenge yubaka urugo.',
    "Nkorenshimwe yaguye inyuma y'urugo.",
  ],
  igufa: ['Inkoni ishikira igufa ntishikira ingeso.'],
  ihora: ["Inkozi y'ikibi ihora igishima isanze cicaye mu nzira"],
  nzira: [
    "Inkozi y'ikibi ihora igishima isanze cicaye mu nzira.",
    'Nariye inyoni nyishi nzira rwungere.',
  ],
  ikuba: ['Inkuba ikuba rimwe.'],
  itora: [
    'Inkware itora i musozi agatima kari mu mwonga.',
    'Intore itora iriye.',
  ],
  ihura: ["Inkware ivunitse ihura n'umwana"],
  iroga: ["Inshirasoni s'iroga n'irya abandi babona"],
  isera: ['Intarabona isera ihaha.'],
  ihaha: ['Intarabona isera ihaha.'],
  inuma: [
    'Inuma ntigira ngo Hugugu iba ivuga ngo Ha uguha.',
    "Ugenda cane ugaserukira kw'isimbo ry'inuma.",
  ],
  uguha: [
    'Inuma ntigira ngo Hugugu iba ivuga ngo Ha uguha.',
    'Uguha aragusahurira.',
  ],
  mwiza: [
    'Inyana imenywa na nyina mu mwiza.',
    'Inyana ntiyoberwa nyina mu mwiza.',
  ],
  ngufi: ['Inzira ngufi yamaze abatwa.'],
  mwezi: ['Inzoga ihera ku mwezi ntihera ku muvumvyi.'],
  isari: ['Isari ntirenga intore.'],
  isazi: [
    "Isazi y'ubute ntirya ku gisebe.",
    "Iza nyabutindi zamazwe n'umugere w'isazi.",
  ],
  ubute: ["Isazi y'ubute ntirya ku gisebe"],
  isema: [
    "Isema y'inkware yota igicaniro inka zitashe.",
    "Isema y'umukecuru yisiga umusoma.",
    "Umukobwa w'isema asera uwishe se.",
  ],
  kanwa: ['Ishano rishana mu kanwa.'],
  itama: [
    'Itama ridatamiye iwawe uravuza induru ntiryumve.',
    'Itama ritakurira utwawe ntirikuvugiriza induru ugowe.',
    'Nkunda kurya yariye igifyera kimumena itama.',
  ],
  iwawe: ['Itama ridatamiye iwawe uravuza induru ntiryumve.'],
  ugowe: ['Itama ritakurira utwawe ntirikuvugiriza induru ugowe.'],
  niryo: ['Ivu rihoze niryo ryotsa inzu.'],
  bizwi: ["Ivy'ejo bizwi n'ejo"],
  ivuze: ['Iyo induru ivuze, umushumba atanga shebuja gupfa.'],
  gupfa: [
    'Iyo induru ivuze, umushumba atanga shebuja gupfa.',
    'Sakirimikibi yatanze umurozi gupfa.',
    'Ujha gupfa abanza kuniha.',
  ],
  bwari: ['Iyo bumenya umwe bwari ikindi.'],
  canje: ["Mbwire gito canje ic'uwundi cuvireho"],
  aruta: [
    'Mpa akabando nandare aruta mpa akate ndambarare.',
    'Ntarataze aruta Mpebezirye.',
    'Utagira ikimuruta, ntagira ico aruta.',
  ],
  akate: ['Mpa akabando nandare aruta mpa akate ndambarare.'],
  mpeze: ['Mpeze akazi yaguye ibutware.'],
  ntaba: [
    'Mukaso ntaba ari nyoko.',
    'Umutware ntaba umwana.',
    "Urahora utyo ntaba mu rugo rw'uwundi.",
    'Uwabaye nya mbwa ntaba nya nka',
  ],
  nyoko: [
    'Mukaso ntaba ari nyoko.',
    'Uhisha ubwari nyoko ntiwarama.',
    'Urucira muka so rugatwara nyoko.',
  ],
  nibwo: [
    'Ndacari muto nibwo butindi.',
    "Ubugaba bw'umugabo nibwo buryo bwiwe",
  ],
  ibano: ['Ndagorora yavunye ibano.', 'Nzikugorora avuna ibano.'],
  nzoga: [
    'Ndagukunda ava ku nzoga, ndakwanka akava ku nzoga.',
    'Ndagukunda ava ku nzoga, ndakwanka akava ku nzoga.',
  ],
  akava: ['Ndagukunda ava ku nzoga, ndakwanka akava ku nzoga.'],
  abera: ['Ndanse abera umusazi.'],
  nikwo: [
    'Ndunguruke nikwo kwiba.',
    'Nigeze nikwo kuryama.',
    'Numvirize nikwo kurya.',
  ],
  kwiba: ['Ndunguruke nikwo kwiba.'],
  usiga: [
    'Ngerageze wa Ngerageze yaburanye Ruvyironza ngo ni umwaba usiga se.',
    'Usiga inyama ku ziko akazisangaho aba atagira abiwe.',
  ],
  amara: ['Nta cica umukondo ngo gisige amara.'],
  wanka: [
    'Nta wanka ivya gusa.',
    'Nta wanka uwamuhaye.',
    'Wanka kugarura impene ikiri hafi yamara kurenga imirambi ukabira nka yo.',
    'Wanka bangwe ntiwanka zana ndabe.',
    "Wanka kurimira umwana w'uwundi uwawe akanamba.",
    'Wanka kwiruka ukagwira ubusa.',
    'Wanka kwumva ukumvira ijeri.',
  ],
  ngohe: ["Nta wuja mu ngohe z'abavukana"],
  icumi: ["Nta wutera icumu mw'icumi"],
  itema: ["Nta wutiza umuhiro mw'itema"],
  ntiha: ['Ntiha uwisize.'],
  avuna: ['Nzikugorora avuna ibano.'],
  iwabo: ["Sindabishaka w'umugore ashakira indaro iwabo"],
  ubana: ['Ubana na suneba ugasuneba nka we.'],
  acika: ['Ushaka umuce acika ijoro.'],
  mango: ['Ubwenge bwinshi bwenga mango.'],
  isaho: ["Ubwenge bw'umwe ni isaho itobotse"],
  nyuma: ['Ubwenge buje mu nyuma buba buje kugucurira.'],
  sibwo: [
    'Ubwenge sibwo bugingo, umupfumu ntiyopfuye.',
    'Ubukuru sibwo bwenge.',
  ],
  ukaba: [
    'Ubwira ikitumva ukaba uriko urata ururimi.',
    'Ugorora ikijumbu ukaba ukivuna.',
    'Uhenda imbwa umuziha ukaba wihenze umuhondo.',
    'Utuka ikitagusubiza ukaba wituka.',
    'Wiba uhetse ukaba wigisha uri mu mugongo.',
    'Wima uwo wahaye ukaba wataye ivya mbere.',
  ],
  uriko: ['Ubwira ikitumva ukaba uriko urata ururimi.'],
  urata: ['Ubwira ikitumva ukaba uriko urata ururimi.'],
  buryo: ["Ubugaba bw'umugabo nibwo buryo bwiwe"],
  bwiwe: ["Ubugaba bw'umugabo nibwo buryo bwiwe"],
  itiro: [
    'Amarushwa ari ku wundi ntakubuza itiro.',
    'Urima  itiro ukimbura ingonera',
  ],
  burya: ['Ubugenegene burya uwabugenewe.'],
  ubuja: ['Ubuja kuzimu si ubufatanamende.', 'Ubuja gusha buratagata.'],
  ubura: ['Ubura urubanza ntubura vuruvuru.'],
  ngoro: ['Ubutoni bwa cane busobesha mu ngoro.'],
  ugaca: ['Uca bugufi ugaca igitoke.'],
  ubusa: ['Uja gusama waraye ubusa nturonka.', 'Wanka kwiruka ukagwira ubusa.'],
  bwira: ['Uko bukeye si ko bwira.'],
  ukuba: ['Ukuba ahabona siko kubonwa.'],
  ikaba: ["Ukunda ikitagukunda ikaba ari nk'imvura igwa mw'ishamba"],
  ukura: [
    'Ukura utabaza ugasaza utamenye.',
    "Ukura umusore ku rugo amutuma, amutuma ifuni y'umuherwa.",
  ],
  ukuri: [
    'Ukuri guca mu ziko ntigusha.',
    'Ukuri kuganza icaha.',
    'Ukuri ntikubuza kuvumba.',
    'Ukuri ntikuvuga uwukuze kuvuga uwukuzi.',
  ],
  icaha: ['Ukuri kuganza icaha.', "Icaha ntikib'icana kivyar'icago."],
  arira: [
    'Umubumvyi arira ku rujo.',
    'Utarashika mu kiganiro arira mu kibari.',
  ],
  umufa: ["Umufa w'imbwebwe unyobwa ugishushe"],
  amira: [
    'Umugabo amira intore ntamira ijambo.',
    'Uwitinze amira ibinoze.',
    "Umwanya w'ubwenge agaya amira",
  ],
  ugana: ['Umugani ugana akariho.'],
  muntu: ['Umugani uva ku muntu, ntuva ku giti.'],
  ntuva: ['Umugani uva ku muntu, ntuva ku giti.'],
  utera: ['Umuhini mushasha utera amabavu.', 'Utera ubureza arabwibanza.'],
  irobe: ["Umukengesha w'impongo ayihigana irobe"],
  asera: ["Umukobwa w'isema asera uwishe se"],
  isoni: ["Umukwe w'isoni arazihambanwa"],
  ahaba: ["Umurozi akinda ahaba, ab'i we ngo mpore"],
  mpore: ["Umurozi akinda ahaba, ab'i we ngo mpore"],
  umusi: ['Umusi umuntu avunitse ni wo amenyerako gucumbagira.'],
  ahora: ['Umusore ahora ahumura ahumiwe.'],
  ufata: ['Umutego mubi ufata nyene wo.'],
  umuti: ["Umuti w'imbwa wahwa ku rugo"],
  wahwa: ["Umuti w'imbwa wahwa ku rugo"],
  agaba: [
    'Umuvyeyi atari so agaba ubona, agatekera ubona.',
    'Utazi agakura agaba umutavu.',
  ],
  akiri: [
    "Umwami w'umuriro umusaba akiri umwana, yamara gukura akakwigiza haruguru.",
  ],
  yitwa: ['Umwana i wabo yitwa umugabo.'],
  umuha: ["Umwana umuha ugutwi akaryana n'umutwe"],
  urume: ['Umwana uribuheke ntumwisha urume.'],
  nkoko: ["Umwana w'inkerebutsi umurabira ku nkoko"],
  akazi: ["Akazi k'ibwami kica uwicaye.", 'Mpeze akazi yaguye ibutware.'],
  anuka: ["Umwana w'uwundi abisha anuka"],
  yimba: ["Umwana w'uwundi yimba aguhisha ivu"],
  icobo: ['Umwansi agucira icobo, Imana ikagucira icanzo.'],
  rwama: [
    'Urutoke rwamenyereye gukomba rwama rugoramye.',
    'Uruja kwama rwama kare.',
  ],
  izosi: [
    'Urutugu rurakura ntirusumba izosi.',
    'Wizigira izosi rikakuvyarira umwingo.',
  ],
  uruja: ['Uruja kwama rwama kare.'],
  kwama: ['Uruja kwama rwama kare.'],
  umuto: ['Urwica umuto rumuvako.'],
  akeza: ['Akeza keza umutima.'],
  abana: ["Urwiganwe rwa mushushe rwamaze abana b'imbeba"],
  akawe: ['Usanze abavandimwe bavunitse uturenge ukuramwo akawe.'],
  ntuta: ['Uta inkoko ntuta ico ibitse.'],
  amatu: ['Utengata amatu abiri ugata rimwe.'],
  ugata: ['Utengata amatu abiri ugata rimwe.'],
  utuka: ['Utuka ikitagusubiza ukaba wituka.'],
  hirya: ['Utwarira hejuru ugatwarwa hirya.'],
  uwazi: ["Uwambuwe n'uwazi ntata ingata"],
  ntata: ["Uwambuwe n'uwazi ntata ingata"],
  irago: ["Uwarasiwe n'ishenyo ntiyorasirwa n'irago"],
  uwica: ['Uwica inkende ntaraba ihaka.'],
  ihaka: ['Uwica inkende ntaraba ihaka.'],
  agira: [
    'Uwo biburiye agira ngo umugoroba uragendeka.',
    "Uhabwa n'uwo yahaye aba agira Imana.",
    'Ukubarira akubesha agira ngo wopfa twojana.',
    'Ukubarira akubesha agira ngo Naguhereye imbwa umuziha irawanka.',
    "Unywa amata y'amasabano agira ngo ifyufyu itera amahere.",
    'Usanze nyina ashaje agira ngo se yataye inka.',
    "Utazi kwuzura, agira ngw'ese ayo nikoreye.",
  ],
  izuba: ['Uwo mutagendanye ntakurabira izuba.'],
  ugira: ['Uwo mutanigana ugira ngo mutsinde vuba maze twire.'],
  twire: ['Uwo mutanigana ugira ngo mutsinde vuba maze twire.'],
  ubuze: ['Ubuze ico avuga avuga ko umwami avyibushe.'],
  avuga: [
    'Ubuze ico avuga avuga ko umwami avyibushe.',
    'Ubuze ico avuga avuga ko umwami avyibushe.',
    'Uronse ico arya ntabura ico avuga.',
  ],
  ufise: ['Ufise umutwe muto awurinda uruguma.'],
  ugaya: ["Ugaya induru y'urunyegeri abanza kuraba ikiyivugije"],
  ugaye: ["Ugaye inyama y'umutwe akanda uwiwe"],
  uwiwe: ["Ugaye inyama y'umutwe akanda uwiwe"],
  ugiye: ['Ugiye iburyasazi azimira nzima (miyonzwa).'],
  nzima: ['Ugiye iburyasazi azimira nzima (miyonzwa).'],
  yenga: ['Ugomba urwamo yenga urwagwa.'],
  ingwe: ["Uhagarikiwe n'ingwe aravoma"],
  ukora: ['Ukora ico azi ajana umwana mu kigega.'],
  ajana: ['Ukora ico azi ajana umwana mu kigega.'],
  acira: ['Ukorora acira aba agabanya indwara.'],
  ukoze: ['Ukoze mu cibo ntabura imihigo.'],
  wopfa: ['Ukubarira akubesha agira ngo wopfa twojana.'],
  ifuni: ["Ukura umusore ku rugo amutuma, amutuma ifuni y'umuherwa"],
  unywa: ["Unywa amata y'amasabano agira ngo ifyufyu itera amahere"],
  amata: [
    "Unywa amata y'amasabano agira ngo ifyufyu itera amahere.",
    'Uteze amata i bwami ntatinya ijoro.',
  ],
  itera: ["Unywa amata y'amasabano agira ngo ifyufyu itera amahere"],
  ijuru: ["Uri musi y'ijuru ntatinya imiravyo"],
  iziko: ['Uronse imbwa ayiharaye ayiharira iziko.'],
  ayita: ['Usanze inyange inyagiwe ayita igikukwe.'],
  abira: ['Ushaka umubira abira akuya.'],
  akuya: ['Ushaka umubira abira akuya.'],
  umuce: ['Ushaka umuce acika ijoro.'],
  ntama: ['Akari mu mpene niko kari mu ntama.'],
  ijoro: ['Ushaka umuce acika ijoro.', 'Uteze amata i bwami ntatinya ijoro.'],
  abiwe: ['Usiga inyama ku ziko akazisangaho aba atagira abiwe.'],
  araza: ['Utazi akaraye araza ifu.'],
  asiga: ['Utazi umwansi asiga umubiri.'],
  azira: ['Utazize inarenze azira inaravyaye.'],
  uteze: ['Uteze amata i bwami ntatinya ijoro.'],
  bwami: ['Uteze amata i bwami ntatinya ijoro.'],
  amano: ["Vyamugoye vy'umugabo yatsitaye amano hakoroka amatwi"],
  ikiri: [
    'Wanka kugarura impene ikiri hafi yamara kurenga imirambi ukabira nka yo.',
  ],
  ndabe: ['Wanka bangwe ntiwanka zana ndabe.'],
  uwawe: ["Wanka kurimira umwana w'uwundi uwawe akanamba"],
  ijeri: ['Wanka kwumva ukumvira ijeri.'],
  mbavu: ["Wigisha umwana w'uwundi kurasa akawuguta mu mbavu"],
  mbere: ['Wima uwo wahaye ukaba wataye ivya mbere.'],
  wumva: ["Inda y'uwundi irakoma induru nti wumva."],
  nkawe: ['Ubana na Suneba ugasunebeza nkawe.'],
  hanze: ['Akagabo karaje ukuguru hanze kitwa imbura mutima.'],
  ibiri: [
    'Umugore ntasutamanga aba ashaka imyuga ibiri.',
    'Imisega ibiri ntinanirwa umugunza.',
  ],
  iyawe: ['Impene mbi ntuyizirikako iyawe.'],
  rundi: ['Urugo ntirwinjira mu rundi.'],
  kitwa: ['Akagabo karaje ukuguru hanze kitwa imbura mutima.'],
  nkore: ["Nkore mbonwe yaguye mw'isemo."],
  // imana: ['Nta wutanga Imana kwihebura.'],
  // igiti: [
  //   'Igiti kigororwa kikiri gito.',
  //   'Igiti umuntu aturira ntakirangamiza.',
  // ],
  // ukuri: ['Ukuri guca mu ziko ntigusha.'],
  // bweze: ['Akanyoni katagurutse ntikamenya iyo bweze.'],
  // ahari: ['Ahari amahoro umuhoro uramwa.'],
  // amaso: ['Intabarirwa ibarirwa nuko amaso atukuye.'],
  // icobo: ['Umwansi agucira icobo Imana ikagucira icanzo.'],
  // imana: ['Umwansi agucira icobo Imana ikagucira icanzo.'],
  // imbwa: ['Abahigi benshi bayobeza imbwa.'],
  // umuti: ['Uwutazi umuti awunyako.'],
  // ubana: [
  //   'Ubana na Suneba ugasunebeza nkawe.',
  //   'Ubana na suneba ugasuneba nka we.',
  // ],

  // urume: ['Ingona iva mu ruzi ikarigata urume.'],
  // urugo: ['Urugo ntirwinjira mu rundi.'],
  // jisho: ['Ababwiriza benshi barashisha mu jisho.'],
  // amazi: ['Amazi masabano ntamara imvyiro.'],
  // inama: ['Umutwe umwe ntiwigira inama.'],
  // ukura: ['Ukura utabaza ugasaza utamenye.'],
  // ibuye: ['Ibuye riserutse ntiryica isuka.'],
  // isuka: ['Ibuye riserutse ntiryica isuka.'],
  // urera: ["Urera nabi ugatukwa n'abakwe."],
}
