import { CURRENT_DATE_TEXT } from '../../constants/strings'
import { getKirundiDate } from '../../lib/dateutils'

export const CurrentDate = () => {
  const date = new Date()
  const currentDate = getKirundiDate({ date })

  return (
    <span className="text-xs text-gray-900 dark:text-gray-100">
      {CURRENT_DATE_TEXT}: {currentDate}
    </span>
  )
}
