import {
  BookOpenIcon,
  CalendarIcon,
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'

import { ENABLE_ARCHIVED_GAMES } from '../../constants/settings'
import { proverb } from '../../lib/proverbs'

type Props = {
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsInfoModalOpen: (value: boolean) => void
  setIsMeaningsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  isGameWon: boolean
  isGameLost: boolean
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsMeaningsModalOpen,
  setIsDatePickerModalOpen,
  setIsSettingsModalOpen,
  setIsStatsModalOpen,
  isGameLost,
  isGameWon,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-5 short:h-auto">
        <div className="flex">
          <InformationCircleIcon
            className="mr-2 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          {ENABLE_ARCHIVED_GAMES && (
            <CalendarIcon
              className="ml-3 h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsDatePickerModalOpen(true)}
            />
          )}
        </div>
        <div>
          <img src="/ijambo.png" alt="Ijambo" className="h-16" />
        </div>
        <div className="right-icons">
          {(isGameWon || isGameLost) && proverb && (
            <BookOpenIcon
              className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
              onClick={() => setIsMeaningsModalOpen(true)}
            />
          )}
          <ChartBarIcon
            className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
