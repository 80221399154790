export const VALID_GUESSES = [
  'ababa',
  'ababi',
  'ababo',
  'abaca',
  'abaco',
  'abacu',
  'abaha',
  'abahe',
  'abaho',
  'abaja',
  'abaje',
  'abami',
  'abana',
  'abane',
  'abari',
  'abasa',
  'abase',
  'abata',
  'abate',
  'abato',
  'abava',
  'abawe',
  'abaye',
  'abayo',
  'abaza',
  'abaze',
  'abazi',
  'abazo',
  'abeho',
  'abera',
  'abere',
  'henda',
  'jamwo',
  'abeyo',
  'ifoto',
  'abeza',
  'hemwe',
  'ipoto',
  'fyina',
  'bwejo',
  'abiba',
  'abica',
  'abice',
  'twote',
  'abiha',
  'abihe',
  'abika',
  'abira',
  'abiri',
  'abita',
  'abiwe',
  'abizi',
  'aboha',
  'aboma',
  'abona',
  'abone',
  'abora',
  'aboro',
  'abuhe',
  'abura',
  'abure',
  'abuza',
  'abuze',
  'acana',
  'acane',
  'aceze',
  'acika',
  'acike',
  'acira',
  'acire',
  'acita',
  'aciwe',
  'aciye',
  'acura',
  'acure',
  'adaha',
  'adani',
  'aduga',
  'aduge',
  'aduha',
  'aduhe',
  'aduza',
  'aduze',
  'afata',
  'afate',
  'afise',
  'afora',
  'agaba',
  'agabe',
  'agaca',
  'agace',
  'agaco',
  'agafi',
  'agafu',
  'agaha',
  'agana',
  'agasa',
  'agase',
  'agasu',
  'agata',
  'agati',
  'agatu',
  'agaya',
  'agema',
  'agena',
  'agene',
  'agera',
  'agere',
  'ageza',
  'ageze',
  'agica',
  'agiha',
  'agira',
  'agire',
  'agiye',
  'agize',
  'agoye',
  'aguha',
  'aguhe',
  'aguma',
  'agume',
  'agura',
  'agure',
  'aguye',
  'ahaba',
  'ahaca',
  'ahaco',
  'ahaha',
  'ahaja',
  'ahako',
  'ahara',
  'ahari',
  'ahava',
  'ahave',
  'ahawe',
  'ahaye',
  'ahayo',
  'ahaze',
  'aheba',
  'ahebe',
  'ahera',
  'aheta',
  'aheza',
  'aheze',
  'ahiga',
  'ahita',
  'ahiwe',
  'ahiye',
  'ahora',
  'ahowe',
  'ahoze',
  'ahuha',
  'ahuje',
  'ahura',
  'ahure',
  'ahuza',
  'ahuze',
  'ajako',
  'ajamu',
  'ajana',
  'ajane',
  'ajayo',
  'ajeyo',
  'akaba',
  'akabi',
  'akabo',
  'akacu',
  'akaga',
  'akago',
  'akaja',
  'akama',
  'akame',
  'akamo',
  'akana',
  'akano',
  'akara',
  'akari',
  'akate',
  'akato',
  'akava',
  'akawa',
  'akayi',
  'akaza',
  'akaze',
  'akazi',
  'akazu',
  'akehe',
  'akeza',
  'akima',
  'akina',
  'akira',
  'akire',
  'akiri',
  'akiva',
  'akiza',
  'akize',
  'akoba',
  'akoma',
  'akora',
  'akore',
  'akoze',
  'akugi',
  'akuki',
  'akuma',
  'akura',
  'akure',
  'akuwe',
  'akuya',
  'akuye',
  'akuza',
  'akuze',
  'amabi',
  'amafa',
  'amafi',
  'amafu',
  'amagi',
  'amaha',
  'amaho',
  'amaja',
  'amaka',
  'amaki',
  'amama',
  'amamo',
  'amano',
  'amara',
  'amare',
  'amase',
  'amaso',
  'amata',
  'amate',
  'amato',
  'amavi',
  'amaza',
  'amaze',
  'amazi',
  'amazu',
  'amena',
  'amera',
  'amere',
  'amero',
  'ameru',
  'amese',
  'ameza',
  'ameze',
  'amezi',
  'amija',
  'amira',
  'amoko',
  'amota',
  'amoya',
  'amoye',
  'amubi',
  'amuca',
  'amufe',
  'amuha',
  'amuhe',
  'amuta',
  'amuzi',
  'anage',
  'anega',
  'anima',
  'anywa',
  'anywe',
  'apana',
  'araba',
  'arabe',
  'araca',
  'araha',
  'araja',
  'araje',
  'araka',
  'arama',
  'arara',
  'arare',
  'arasa',
  'arava',
  'araza',
  'arazi',
  'arega',
  'areka',
  'areke',
  'arera',
  'areza',
  'ariga',
  'ariha',
  'arihe',
  'ariho',
  'ariko',
  'arima',
  'arime',
  'arimu',
  'arira',
  'arire',
  'ariwa',
  'ariwe',
  'ariwo',
  'ariya',
  'ariye',
  'arize',
  'arizi',
  'aroga',
  'arose',
  'arota',
  'aruca',
  'aruha',
  'arura',
  'aruta',
  'aruwe',
  'asaba',
  'asabe',
  'asama',
  'asare',
  'asasa',
  'asesa',
  'asese',
  'aside',
  'asiga',
  'asize',
  'asoma',
  'asome',
  'asosa',
  'asuka',
  'asuke',
  'ataba',
  'ataha',
  'ataho',
  'ataje',
  'atame',
  'atanu',
  'atari',
  'atate',
  'atatu',
  'atava',
  'ataye',
  'ataza',
  'ataze',
  'atazi',
  'ateba',
  'atebe',
  'atega',
  'atema',
  'ateme',
  'atera',
  'atere',
  'atewe',
  'ateye',
  'ateza',
  'ateze',
  'atiha',
  'atize',
  'atoba',
  'atoca',
  'atoka',
  'atona',
  'atora',
  'atore',
  'atoye',
  'atoze',
  'atuka',
  'atuma',
  'atume',
  'atura',
  'atuzi',
  'avayo',
  'avome',
  'avuga',
  'avuge',
  'avuka',
  'avuye',
  'avuza',
  'avuze',
  'awuha',
  'awuhe',
  'ayacu',
  'ayaga',
  'ayahe',
  'ayari',
  'ayasa',
  'ayava',
  'ayawe',
  'ayera',
  'ayiha',
  'ayihe',
  'ayita',
  'ayiwe',
  'azana',
  'azane',
  'azeza',
  'aziha',
  'azihe',
  'azime',
  'azira',
  'azire',
  'aziye',
  'azize',
  'azoba',
  'azobe',
  'azoca',
  'azoce',
  'azoha',
  'azoja',
  'azota',
  'azova',
  'azoza',
  'azoze',
  'azuka',
  'azura',
  'azuwe',
  'babwo',
  'bagwa',
  'bagwe',
  'bambe',
  'bampa',
  'bamve',
  'bamwe',
  'bamye',
  'bande',
  'bandi',
  'banga',
  'banje',
  'banka',
  'banse',
  'bansi',
  'banta',
  'bantu',
  'banyu',
  'banza',
  'banzi',
  'bapfa',
  'bapfe',
  'barya',
  'barye',
  'baryo',
  'basya',
  'bavyo',
  'bazwi',
  'benda',
  'benga',
  'berwa',
  'besha',
  'bicwa',
  'bicwe',
  'bigwa',
  'bimba',
  'bimwa',
  'bimwe',
  'bimye',
  'binca',
  'bindi',
  'bintu',
  'binza',
  'bipfa',
  'bipfe',
  'bipfu',
  'birya',
  'birye',
  'bisha',
  'bishe',
  'biswe',
  'bitwa',
  'bitwe',
  'bizwi',
  'bompi',
  'bonda',
  'bonsa',
  'bonta',
  'bopfa',
  'bosha',
  'boshe',
  'bozwe',
  'bugwa',
  'bukwi',
  'bumba',
  'bumva',
  'bumve',
  'bumwe',
  'bundi',
  'bunge',
  'buntu',
  'bunze',
  'bupfu',
  'burya',
  'buryo',
  'busha',
  'busho',
  'bwaba',
  'bwabe',
  'bwabo',
  'bwaca',
  'bwaco',
  'bwacu',
  'bwaho',
  'bwaje',
  'bwako',
  'bwama',
  'bwame',
  'bwami',
  'bwana',
  'bwari',
  'bwato',
  'bwawa',
  'bwawe',
  'bwawo',
  'bwayo',
  'bwaze',
  'bwazo',
  'bwera',
  'bwica',
  'bwije',
  'bwira',
  'bwire',
  'bwite',
  'bwiwe',
  'bwiye',
  'bwiza',
  'bwoba',
  'bwoca',
  'bwoko',
  'bworo',
  'bwose',
  'bwoya',
  'bwoye',
  'cabwo',
  'cakwe',
  'camwa',
  'camye',
  'canga',
  'canje',
  'canke',
  'canyu',
  'carwo',
  'caryo',
  'catsi',
  'catwo',
  'cavyo',
  'cenda',
  'cezwa',
  'cezwe',
  'cicwa',
  'cigwa',
  'ciswe',
  'citwa',
  'citwe',
  'civye',
  'combo',
  'conze',
  'cumba',
  'cumve',
  'cumye',
  'cunga',
  'dunda',
  'dupfa',
  'dupfe',
  'dutwe',
  'duzwa',
  'egeka',
  'egera',
  'egoda',
  'egome',
  'ekada',
  'emera',
  'emere',
  'emeza',
  'erega',
  'ereka',
  'ereke',
  'erura',
  'fanta',
  'fasha',
  'fatwa',
  'fonda',
  'fukwa',
  'funga',
  'futwa',
  'fuvya',
  'fyeta',
  'fyete',
  'fyine',
  'fyura',
  'gahwa',
  'ganza',
  'gasho',
  'gaswi',
  'gatwe',
  'genda',
  'gende',
  'gipfa',
  'gipfe',
  'gipfu',
  'girwa',
  'girwe',
  'gizwe',
  'gomba',
  'gonda',
  'twize',
  'gubwa',
  'gukwa',
  'gumya',
  'gumye',
  'gupfa',
  'gurwa',
  'gusha',
  'gutwi',
  'akine',
  'gutya',
  'nsabe',
  'ewana',
  'ugeme',
  'gutyo',
  'gwama',
  'iriza',
  'gwara',
  'uyaze',
  'ndase',
  'gwira',
  'nsare',
  'yemye',
  'gwiwe',
  'gwiza',
  'gwize',
  'hagwa',
  'hagwe',
  'hakwa',
  'hamwa',
  'hamwe',
  'handa',
  'hande',
  'handi',
  'hanga',
  'hange',
  'hanje',
  'hantu',
  'hanyu',
  'hanze',
  'hapfa',
  'hapfe',
  'harya',
  'haryo',
  'havyo',
  'hemba',
  'hembe',
  'hesha',
  'heshe',
  'hicwa',
  'hicwe',
  'hinga',
  'hinge',
  'hirwa',
  'hirya',
  'hiswe',
  'hitwa',
  'hitwe',
  'homba',
  'hombe',
  'hompi',
  'honda',
  'honye',
  'hoshi',
  'hukwa',
  'hunga',
  'hunge',
  'huvya',
  'hwata',
  'ibaba',
  'ibabe',
  'ibabi',
  'ibaca',
  'ibaha',
  'ibahe',
  'ibaho',
  'ibaje',
  'ibama',
  'ibane',
  'ibani',
  'ibara',
  'ibata',
  'ibati',
  'ibavu',
  'ibaye',
  'ibaze',
  'ibega',
  'ibeho',
  'ibeko',
  'ibeni',
  'ibera',
  'ibere',
  'ibete',
  'ibeyi',
  'ibeze',
  'ibiba',
  'ibibi',
  'ibica',
  'ibice',
  'ibicu',
  'ibido',
  'ibifi',
  'ibigo',
  'ibihe',
  'ibija',
  'ibika',
  'ibiki',
  'ibiri',
  'ibiro',
  'ibisa',
  'ibise',
  'ubufu',
  'ibisi',
  'ihane',
  'nyiba',
  'ibita',
  'ibiti',
  'nitwe',
  'ibiva',
  'ibivi',
  'nyike',
  'twige',
  'uduki',
  'ibivu',
  'ibiza',
  'ibobo',
  'iboma',
  'ibona',
  'vyoze',
  'bonse',
  'ibone',
  'akubu',
  'isema',
  'urota',
  'uwota',
  'zande',
  'zipfe',
  'urera',
  'kanda',
  'yande',
  'zanse',
  'tamwo',
  'ibowa',
  'ibuha',
  'ibuka',
  'urote',
  'isusa',
  'kuvyo',
  'ibura',
  'kwina',
  'iburi',
  'isope',
  'shure',
  'pompa',
  'ndaho',
  'ibuye',
  'ibuze',
  'icago',
  'icaha',
  'icahi',
  'icana',
  'icara',
  'icari',
  'icasa',
  'icava',
  'icawe',
  'icayi',
  'icayo',
  'icema',
  'icera',
  'iceri',
  'icese',
  'icete',
  'iceyi',
  'icibo',
  'icika',
  'icike',
  'icira',
  'iciro',
  'iciye',
  'iciza',
  'icoba',
  'icobo',
  'icogo',
  'icubi',
  'icuka',
  'icuma',
  'icumi',
  'icumu',
  'icupa',
  'icuya',
  'ideni',
  'idini',
  'idiri',
  'idubu',
  'iduga',
  'iduha',
  'iduhe',
  'iduka',
  'iduza',
  'ifata',
  'ifate',
  'ifazi',
  'ifeke',
  'ifero',
  'ifeza',
  'ifira',
  'ifise',
  'ifuhe',
  'ifuku',
  'ifuni',
  'ifuro',
  'ifuru',
  'ifuti',
  'igaba',
  'igabo',
  'igaha',
  'igana',
  'igapo',
  'igari',
  'igasa',
  'igata',
  'igawa',
  'igayi',
  'igera',
  'igesa',
  'igeza',
  'igeze',
  'igezi',
  'igica',
  'igice',
  'igico',
  'igicu',
  'igifi',
  'igihe',
  'igiki',
  'igira',
  'igire',
  'igisa',
  'igisu',
  'igiti',
  'igito',
  'igiye',
  'igize',
  'igono',
  'igoti',
  'igoye',
  'igufa',
  'igugu',
  'iguhe',
  'iguma',
  'igume',
  'iguye',
  'ihana',
  'ihani',
  'ihari',
  'ihasa',
  'ihawe',
  'iheba',
  'ihema',
  'ihene',
  'ihera',
  'ihere',
  'ihero',
  'iheze',
  'ihiga',
  'ihina',
  'ihire',
  'ihita',
  'ihiye',
  'ihoze',
  'ihuba',
  'ihuna',
  'ihuri',
  'ihuye',
  'ihuza',
  'ijage',
  'ijana',
  'ijayo',
  'ijigo',
  'ijipo',
  'ijoro',
  'ijuru',
  'ikaba',
  'ikaja',
  'ikara',
  'ikaro',
  'ikata',
  'ikava',
  'ikawa',
  'ikaye',
  'ikayi',
  'ikaza',
  'ikaze',
  'ikazi',
  'ikeba',
  'ikete',
  'ikibi',
  'ikica',
  'ikida',
  'ikido',
  'ikigo',
  'ikihe',
  'ikija',
  'ikime',
  'ikiri',
  'ikiro',
  'ikivi',
  'ikiya',
  'ikiye',
  'ikiyo',
  'ikiza',
  'ikize',
  'ikizi',
  'ikofe',
  'ikofi',
  'ikoma',
  'ikomu',
  'ikopi',
  'ikora',
  'ikore',
  'ikori',
  'ikosa',
  'ikoti',
  'ikoze',
  'ikuga',
  'ikuma',
  'ikuta',
  'ikuwe',
  'ikuye',
  'ikuza',
  'ikuze',
  'imana',
  'imara',
  'imari',
  'imayi',
  'imaze',
  'imena',
  'imera',
  'imeri',
  'imero',
  'imeya',
  'imeza',
  'imeze',
  'imibu',
  'imice',
  'imico',
  'imina',
  'imira',
  'imisa',
  'imise',
  'imisi',
  'imiti',
  'imizi',
  'imoko',
  'imota',
  'imoto',
  'imuha',
  'imuhe',
  'imuri',
  'imuva',
  'inabi',
  'inaga',
  'inama',
  'inara',
  'inawe',
  'inazi',
  'indwi',
  'indya',
  'inema',
  'ineza',
  'iniro',
  'inivo',
  'inkwi',
  'inota',
  'inoti',
  'inoye',
  'inoze',
  'inshi',
  'inswa',
  'inswi',
  'insya',
  'insyo',
  'inuma',
  'inusu',
  'inywa',
  'inywe',
  'inzya',
  'ipanu',
  'ipasi',
  'ipawa',
  'ipepu',
  'ipera',
  'ipete',
  'ipima',
  'ipine',
  'ipome',
  'ipori',
  'iraba',
  'irabe',
  'iraga',
  'iragi',
  'iraho',
  'iraje',
  'iraka',
  'irama',
  'irame',
  'irare',
  'iraro',
  'irate',
  'iraye',
  'iraza',
  'irazi',
  'ireha',
  'ireka',
  'ireke',
  'irema',
  'irera',
  'iriba',
  'irica',
  'irido',
  'irigi',
  'irihe',
  'iriho',
  'iriko',
  'irima',
  'iripu',
  'iriri',
  'iriro',
  'iriwe',
  'iriya',
  'iriye',
  'irobe',
  'irobo',
  'iroha',
  'irore',
  'irugu',
  'iruka',
  'irura',
  'iruri',
  'iruta',
  'irute',
  'isaba',
  'isage',
  'isaha',
  'isaho',
  'isahu',
  'isake',
  'isano',
  'isare',
  'isari',
  'isase',
  'isaso',
  'isato',
  'isazi',
  'isefu',
  'isega',
  'iseka',
  'isevu',
  'ishwi',
  'isiha',
  'isima',
  'isiya',
  'isogi',
  'isoko',
  'isoni',
  'isoro',
  'isosa',
  'isosi',
  'isowi',
  'isoya',
  'isugi',
  'isuka',
  'isuku',
  'isumo',
  'isumu',
  'isupu',
  'isura',
  'itaba',
  'itabe',
  'itabi',
  'itabu',
  'itaha',
  'itaje',
  'itako',
  'itama',
  'itanu',
  'itara',
  'itari',
  'itatu',
  'itayi',
  'iteba',
  'itege',
  'iteka',
  'iteke',
  'itera',
  'itere',
  'itewe',
  'iteye',
  'iteza',
  'itike',
  'itima',
  'itiro',
  'itiye',
  'itize',
  'itora',
  'itoto',
  'ituba',
  'ituma',
  'itume',
  'iture',
  'ituro',
  'ituta',
  'ituza',
  'ivana',
  'ivano',
  'ivoka',
  'ivomo',
  'ivubi',
  'ivuga',
  'ivuge',
  'ivuka',
  'ivuna',
  'ivutu',
  'ivuye',
  'ivuza',
  'ivuze',
  'iwabo',
  'iwacu',
  'iwawe',
  'iwayo',
  'iwese',
  'iwita',
  'iwiwe',
  'iyaba',
  'iyabo',
  'iyacu',
  'iyamu',
  'iyari',
  'iyata',
  'iyawo',
  'iyera',
  'iyihe',
  'iyiri',
  'iyita',
  'iyiwe',
  'iyoba',
  'iyugi',
  'izamu',
  'izana',
  'izari',
  'izera',
  'izere',
  'izero',
  'izihe',
  'izija',
  'iziko',
  'izina',
  'iziri',
  'izita',
  'iziwe',
  'iziza',
  'izize',
  'izoba',
  'izobe',
  'izoca',
  'izoha',
  'izoja',
  'izosi',
  'izota',
  'izova',
  'izoza',
  'izuba',
  'izuka',
  'izura',
  'izuri',
  'izuru',
  'jambo',
  'janye',
  'jenda',
  'jisho',
  'kabwa',
  'kabwo',
  'kagwa',
  'kagwi',
  'kakwo',
  'kambi',
  'kampe',
  'kamwe',
  'kandi',
  'kanje',
  'kantu',
  'kanwa',
  'kanya',
  'kanyu',
  'kanzu',
  'karya',
  'karyo',
  'katwa',
  'kavyo',
  'kazwi',
  'kenya',
  'kevya',
  'kibwa',
  'kigwa',
  'kigwi',
  'kimwe',
  'kinda',
  'kindi',
  'kinga',
  'kintu',
  'kinya',
  'kinza',
  'kirya',
  'kitwa',
  'kizwa',
  'kobwa',
  'komwa',
  'kondo',
  'kotwa',
  'kubwa',
  'kugwa',
  'kumbe',
  'kumpa',
  'kumwa',
  'kumwe',
  'tunye',
  'abuzi',
  'bimpe',
  'dukwe',
  'gitwe',
  'kunda',
  'kundi',
  'kunja',
  'kunta',
  'kuntu',
  'kunwa',
  'kunya',
  'kurwa',
  'kurya',
  'mbuza',
  'mpora',
  'ngako',
  'kuryo',
  'kwaba',
  'kwabo',
  'kwaco',
  'kwacu',
  'kwaha',
  'kwaho',
  'kwaje',
  'kwaka',
  'kwama',
  'kwana',
  'kwari',
  'kwawe',
  'kwaya',
  'kwayo',
  'kwaza',
  'kwaga',
  'amaga',
  'uruno',
  'hisha',
  'urime',
  'kimpe',
  'jisha',
  'cisha',
  'kirye',
  'kwazo',
  'kwega',
  'kwema',
  'kwera',
  'kweri',
  'kweza',
  'kwezi',
  'kwiba',
  'kwica',
  'kwiga',
  'kwiha',
  'kwima',
  'kwira',
  'kwita',
  'kwiwe',
  'kwiza',
  'kwoba',
  'kwoga',
  'kwoma',
  'kwomu',
  'kwona',
  'kwosa',
  'kwose',
  'kwota',
  'kwuma',
  'kwuta',
  'kwuza',
  'mahwa',
  'majwi',
  'mambo',
  'mambu',
  'mango',
  'manza',
  'mapfa',
  'masho',
  'matwi',
  'mbaha',
  'mbaho',
  'mbara',
  'mbavu',
  'mbaye',
  'mbaza',
  'mbeba',
  'mbega',
  'mbeho',
  'mbera',
  'mbere',
  'mbeyo',
  'mbibe',
  'mbiga',
  'mbika',
  'mbisi',
  'mbizi',
  'mboga',
  'mbohe',
  'mbona',
  'mbone',
  'mboyi',
  'mbuga',
  'mbure',
  'mburi',
  'mbuto',
  'mbuze',
  'menya',
  'menye',
  'menyo',
  'mfata',
  'mfate',
  'mfise',
  'mfura',
  'migwi',
  'minwa',
  'minwe',
  'mitsi',
  'mitwe',
  'mivyi',
  'mpaga',
  'mpaka',
  'mpako',
  'mpama',
  'mpari',
  'mpawe',
  'mpaye',
  'mpeba',
  'mpemu',
  'mpene',
  'mpera',
  'mpeta',
  'mpeze',
  'mpima',
  'mpome',
  'mpore',
  'mpuzu',
  'mugwi',
  'mukwa',
  'mukwe',
  'mumpe',
  'mumve',
  'munsi',
  'muntu',
  'munwa',
  'munwe',
  'munyu',
  'munzi',
  'munzu',
  'mupfa',
  'murya',
  'murye',
  'musha',
  'mushu',
  'mutsi',
  'mutwa',
  'mutwe',
  'mutyo',
  'mvane',
  'mveyo',
  'mviro',
  'mvuga',
  'mvuge',
  'mvugo',
  'mvume',
  'mvuna',
  'mvune',
  'mvura',
  'mvuto',
  'mvuye',
  'mvuze',
  'mwaba',
  'mwaje',
  'mwaka',
  'mwama',
  'mwame',
  'mwami',
  'mwana',
  'mwara',
  'mwari',
  'mwaro',
  'mwava',
  'mweho',
  'mwene',
  'mwera',
  'mwese',
  'mwete',
  'mweze',
  'mwezi',
  'mwica',
  'mwice',
  'mwige',
  'mwiha',
  'mwihe',
  'mwiko',
  'mwira',
  'mwita',
  'mwiyu',
  'mwiza',
  'mwize',
  'mwoba',
  'mwobo',
  'mwoca',
  'mworo',
  'mwoza',
  'mwoze',
  'myaka',
  'myiza',
  'myobo',
  'namba',
  'nambu',
  'nampe',
  'namwe',
  'namye',
  'nanga',
  'mbisa',
  'ndima',
  'nyima',
  'apima',
  'twima',
  'rimpe',
  'ngayo',
  'ngiyo',
  'ngure',
  'nkina',
  'nteka',
  'uroze',
  'usaze',
  'nanje',
  'nanka',
  'nanse',
  'napfe',
  'narye',
  'natwe',
  'navyo',
  'nceho',
  'ncika',
  'ncike',
  'ncire',
  'nciye',
  'ncuna',
  'ncuro',
  'ncuti',
  'ndaba',
  'ndabe',
  'ndaha',
  'ndaja',
  'ndaje',
  'ndara',
  'ndaro',
  'ndata',
  'ndava',
  'ndaza',
  'ndazi',
  'ndeka',
  'ndeke',
  'ndema',
  'ndero',
  'ndeza',
  'ndiba',
  'ndica',
  'ndiga',
  'ndiha',
  'ndiho',
  'ndiko',
  'ndimi',
  'ndimo',
  'ndire',
  'ndiwe',
  'ndiye',
  'ndize',
  'ndobo',
  'ndoga',
  'ndome',
  'ndore',
  'ndosa',
  'ndota',
  'ndoto',
  'nduga',
  'nduge',
  'nduza',
  'nduze',
  'nezwa',
  'ngabo',
  'ngaca',
  'ngaha',
  'ngaho',
  'ngana',
  'ngano',
  'ngazi',
  'ngero',
  'ngeso',
  'ngeze',
  'ngira',
  'ngire',
  'ngiro',
  'ngiye',
  'ngize',
  'ngobe',
  'ngogo',
  'ngoma',
  'ngora',
  'ngoro',
  'ngowe',
  'ngozi',
  'ngufi',
  'nguha',
  'nguhe',
  'nguma',
  'ngume',
  'ngura',
  'nguvu',
  'nguze',
  'nguzi',
  'nicwa',
  'nicwe',
  'nimba',
  'ninde',
  'nirye',
  'nishe',
  'nitwa',
  'nivyo',
  'njana',
  'njane',
  'njemu',
  'ivure',
  'ubuzi',
  'imuka',
  'njeyo',
  'nkaba',
  'nkaja',
  'nkako',
  'nkama',
  'nkana',
  'nkava',
  'nkawe',
  'nkaza',
  'nkeka',
  'nkera',
  'nkeya',
  'nkeyi',
  'nkeza',
  'nkiba',
  'nkike',
  'nkino',
  'nkire',
  'nkiri',
  'nkiva',
  'nkiza',
  'nkize',
  'nkoko',
  'nkoma',
  'nkomu',
  'nkona',
  'nkone',
  'nkoni',
  'nkono',
  'nkora',
  'nkore',
  'nkoro',
  'nkota',
  'nkovu',
  'nkoze',
  'nkozi',
  'nkuka',
  'nkumi',
  'nkura',
  'nkure',
  'nkuru',
  'nkuwe',
  'nkuye',
  'nkuzi',
  'nomye',
  'nonke',
  'nonse',
  'nopfa',
  'norya',
  'nosha',
  'nsaba',
  'nsago',
  'nsasa',
  'nsato',
  'nseko',
  'nsesa',
  'nsome',
  'nsuka',
  'nsuke',
  'ntaba',
  'ntabe',
  'ntabo',
  'ntaca',
  'ntaco',
  'ntahe',
  'ntaho',
  'ntaja',
  'ntaje',
  'ntama',
  'ntara',
  'ntare',
  'ntari',
  'ntata',
  'ntate',
  'ntawe',
  'ntayo',
  'ntasa',
  'ntaza',
  'ntaze',
  'ntazi',
  'ntebe',
  'ntega',
  'nteko',
  'ntera',
  'ntere',
  'ntete',
  'ntewe',
  'nteye',
  'nteze',
  'ntiba',
  'ntibe',
  'ntiri',
  'ntita',
  'ntiwi',
  'ntiza',
  'ntizo',
  'ntoke',
  'ntora',
  'ntore',
  'ntoye',
  'ntoyi',
  'ntoza',
  'ntuba',
  'ntube',
  'ntuhe',
  'ntuje',
  'ntuka',
  'ntuma',
  'ntume',
  'ntumo',
  'ntura',
  'nturi',
  'ntuve',
  'ntuza',
  'ntuze',
  'ntuzi',
  'numpe',
  'numva',
  'numve',
  'numwe',
  'numye',
  'nunge',
  'nunze',
  'nyaco',
  'nyaho',
  'nyama',
  'nyana',
  'nyawe',
  'nyawo',
  'nyayo',
  'nyene',
  'nyina',
  'nyoko',
  'nyoma',
  'nyoni',
  'nyuma',
  'nyuzi',
  'nzana',
  'nzane',
  'nzara',
  'nzeko',
  'nzero',
  'nzeza',
  'nzige',
  'nzima',
  'nzira',
  'nziza',
  'nzoba',
  'nzobe',
  'nzoca',
  'nzoga',
  'nzoha',
  'nzoja',
  'nzoka',
  'nzota',
  'nzovu',
  'nzoya',
  'nzoza',
  'nzoze',
  'nzuzi',
  'omoka',
  'nkuko',
  'onona',
  'oroha',
  'orora',
  'oyaha',
  'oyaye',
  'panga',
  'pfuma',
  'iyuba',
  'pinda',
  'pinga',
  'ikiba',
  'ponda',
  'pwaro',
  'ramba',
  'rembo',
  'bwuma',
  'renga',
  'igori',
  'renza',
  'rerwa',
  'resha',
  'rigwa',
  'rimwe',
  'rimwo',
  'rinda',
  'rinde',
  'rindi',
  'ripfa',
  'rirya',
  'risha',
  'rizwi',
  'ronga',
  'ronka',
  'rugwa',
  'rugwe',
  'rumbu',
  'rumwe',
  'rundi',
  'runtu',
  'rupfu',
  'rurya',
  'rurye',
  'rusha',
  'rushi',
  'rusyo',
  'rutwe',
  'rwaba',
  'rwabo',
  'rwaca',
  'rwaco',
  'rwacu',
  'rwaho',
  'rwako',
  'rwama',
  'rwame',
  'rwamo',
  'rwana',
  'rwari',
  'rwava',
  'rwawe',
  'honja',
  'rwawo',
  'rwaya',
  'rwayo',
  'rwazo',
  'rwego',
  'rweru',
  'rwimo',
  'rwire',
  'rwiri',
  'rwiwe',
  'rwiza',
  'rwoba',
  'rwose',
  'rwuba',
  'rwuma',
  'rwume',
  'ryaba',
  'ryabo',
  'ryaco',
  'ryacu',
  'ryaga',
  'ryaho',
  'ryaje',
  'ryaka',
  'ryama',
  'ryari',
  'ryava',
  'icuba',
  'ryawe',
  'ryawo',
  'nyica',
  'nzica',
  'ryayo',
  'ryazo',
  'ryera',
  'ryica',
  'ryiha',
  'mpeka',
  'ryoha',
  'ryiwe',
  'ryiza',
  'ryoba',
  'ryoca',
  'ncira',
  'icova',
  'nsiga',
  'vyina',
  'nkuyo',
  'ryomu',
  'ryose',
  'sabwe',
  'sanga',
  'senga',
  'senge',
  'senya',
  'shahu',
  'shaka',
  'shano',
  'shavu',
  'shaza',
  'shefu',
  'shehe',
  'shemi',
  'shika',
  'shima',
  'shira',
  'shona',
  'shora',
  'shoza',
  'shura',
  'simba',
  'simbe',
  'sindi',
  'sinje',
  'sinse',
  'sinza',
  'sinze',
  'sinzi',
  'sivyo',
  'sokwe',
  'songa',
  'sonza',
  'sukwa',
  'sutwa',
  'tamba',
  'tanga',
  'tangi',
  'tanya',
  'temba',
  'tenga',
  'terwa',
  'tevya',
  'timba',
  'tinda',
  'tinya',
  'tonda',
  'tonde',
  'tongo',
  'tonya',
  'torwa',
  'tubwa',
  'tugwe',
  'tugwi',
  'tukwa',
  'tundi',
  'tunga',
  'turya',
  'turye',
  'tutsi',
  'twaba',
  'twabo',
  'twaco',
  'twaho',
  'twaje',
  'twama',
  'twame',
  'twara',
  'twari',
  'twawe',
  'twawo',
  'twayo',
  'twazo',
  'tweho',
  'twera',
  'twese',
  'twihe',
  'twita',
  'twiwe',
  'twiza',
  'twoba',
  'twoca',
  'twoge',
  'twoha',
  'twoje',
  'twose',
  'twoye',
  'twoze',
  'iyubu',
  'ubace',
  'ubage',
  'ubaha',
  'ubahe',
  'ubaho',
  'ubaka',
  'ubana',
  'ubane',
  'ubate',
  'ubave',
  'ubaye',
  'upime',
  'ibora',
  'twiga',
  'yimpe',
  'ubaza',
  'ubaze',
  'ubeho',
  'ubera',
  'ubere',
  'ubeyo',
  'ubeze',
  'ubiba',
  'ubibe',
  'ubice',
  'ubihe',
  'ubika',
  'ubike',
  'ubize',
  'ubizi',
  'ubome',
  'ubona',
  'ubone',
  'uboze',
  'ububi',
  'ubugi',
  'ubuhe',
  'ubuho',
  'ubuja',
  'ubuki',
  'ubuku',
  'ubura',
  'ubure',
  'uburi',
  'uburo',
  'ubusa',
  'ubute',
  'ubuto',
  'ubuva',
  'ubuyi',
  'uceze',
  'ucika',
  'ucike',
  'ucira',
  'ucire',
  'uciye',
  'ucura',
  'ucuze',
  'udaca',
  'udide',
  'udufi',
  'uduga',
  'uduge',
  'uduha',
  'uduhe',
  'uduka',
  'uduta',
  'uduti',
  'uduze',
  'ufata',
  'ufate',
  'ufise',
  'ugaca',
  'ugaha',
  'ugana',
  'ugara',
  'ugaya',
  'ugene',
  'ugere',
  'ugeza',
  'ugeze',
  'ugiha',
  'ugihe',
  'ugira',
  'ugire',
  'ugite',
  'ugiye',
  'ugize',
  'ugona',
  'ugoye',
  'uguca',
  'uguma',
  'ugume',
  'ugumu',
  'ugure',
  'uguta',
  'ugute',
  'uguye',
  'uhabe',
  'uhage',
  'uhare',
  'uhari',
  'uhawe',
  'uhaye',
  'uhaza',
  'uhebe',
  'uhema',
  'uheze',
  'uhita',
  'uhoma',
  'uhuha',
  'uhuje',
  'uhura',
  'uhure',
  'uhuye',
  'ujana',
  'ujane',
  'ujemu',
  'ukaba',
  'ukaja',
  'ukama',
  'ukare',
  'ukase',
  'ukava',
  'ukaza',
  'ukaze',
  'ukica',
  'ukina',
  'ukira',
  'ukire',
  'ukiri',
  'ukize',
  'ukome',
  'ukora',
  'ukore',
  'ukoze',
  'ukuba',
  'ukuja',
  'ukuma',
  'genza',
  'ukura',
  'ukure',
  'irori',
  'abize',
  'nanke',
  'ngaba',
  'ukuri',
  'uhaze',
  'ukiza',
  'kanse',
  'anika',
  'ipano',
  'basha',
  'harye',
  'igore',
  'mwiga',
  'mwoga',
  'nyika',
  'ukuva',
  'ukuwe',
  'ukuza',
  'umara',
  'umare',
  'izane',
  'umaze',
  'ibiyo',
  'umera',
  'umere',
  'umeza',
  'umeze',
  'umira',
  'umire',
  'umiye',
  'umota',
  'umube',
  'umubi',
  'umubu',
  'umuce',
  'umuco',
  'umuda',
  'umufa',
  'umuha',
  'umuhe',
  'umuja',
  'umuji',
  'umuma',
  'umuna',
  'umupo',
  'umusi',
  'umuti',
  'umuto',
  'umuvo',
  'imivo',
  'umuza',
  'umuzi',
  'umuzo',
  'unama',
  'unihe',
  'unoze',
  'unuze',
  'unywa',
  'unywe',
  'upima',
  'uraba',
  'urabe',
  'uraca',
  'uraho',
  'izacu',
  'izisa',
  'uraja',
  'uraje',
  'uraka',
  'urama',
  'urara',
  'urare',
  'urava',
  'uraza',
  'urazi',
  'ureha',
  'ureka',
  'ureke',
  'uriga',
  'urihe',
  'uriho',
  'uriko',
  'urima',
  'urira',
  'urire',
  'urite',
  'uriya',
  'uriye',
  'urize',
  'uroba',
  'uroga',
  'urubu',
  'uruda',
  'urufi',
  'urugi',
  'urugo',
  'uruho',
  'uruhu',
  'urujo',
  'urume',
  'ururo',
  'uruta',
  'njeko',
  'uruti',
  'uruvi',
  'uruvo',
  'uruvu',
  'uruyo',
  'uruzi',
  'usaba',
  'usabe',
  'usase',
  'usibe',
  'usiga',
  'usoma',
  'mupfe',
  'vyura',
  'tumwe',
  'twice',
  'ugura',
  'uvoma',
  'usome',
  'usosa',
  'usuke',
  'utaba',
  'utaha',
  'utahe',
  'utaje',
  'utako',
  'utama',
  'utari',
  'utaza',
  'utazi',
  'uteba',
  'utege',
  'uteka',
  'uteke',
  'uteko',
  'utema',
  'utera',
  'utere',
  'utewe',
  'uteye',
  'uteza',
  'uteze',
  'utiye',
  'utoba',
  'utora',
  'utore',
  'utugo',
  'utuma',
  'utume',
  'utuzi',
  'uvuga',
  'uvuge',
  'uvume',
  'uvuye',
  'uvuza',
  'uvuze',
  'uwaba',
  'uwabo',
  'uwaka',
  'uwama',
  'uwari',
  'uwava',
  'uwawe',
  'uwera',
  'uwiba',
  'uwica',
  'uwiga',
  'uwima',
  'uwita',
  'uwiwe',
  'uwoba',
  'uwoga',
  'uwuba',
  'uwuca',
  'uwuha',
  'uwuhe',
  'uwuja',
  'uwuje',
  'uwuri',
  'uwusa',
  'uwuza',
  'uwuzi',
  'uyage',
  'uyeze',
  'uyiha',
  'uyihe',
  'uyuhe',
  'uzana',
  'uzane',
  'uzihe',
  'uzima',
  'uzime',
  'uzoba',
  'uzoca',
  'uzoce',
  'uzoha',
  'uzoja',
  'uzoje',
  'uzoza',
  'uzoze',
  'uzuke',
  'uzuza',
  'vanga',
  'vinyu',
  'vugwa',
  'vumwa',
  'vyaba',
  'vyabo',
  'vyaca',
  'vyaco',
  'vyacu',
  'vyago',
  'vyaha',
  'vyahi',
  'vyaho',
  'vyaje',
  'vyaka',
  'vyako',
  'vyama',
  'vyana',
  'vyara',
  'vyari',
  'vyaro',
  'vyasa',
  'vyava',
  'vyawe',
  'vyawo',
  'vyaya',
  'vyayo',
  'vyaza',
  'vyazo',
  'vyera',
  'vyeri',
  'vyeze',
  'vyibe',
  'vyica',
  'vyice',
  'vyiha',
  'vyiwe',
  'vyiza',
  'vyoba',
  'vyobo',
  'vyoca',
  'vyoje',
  'vyosa',
  'vyose',
  'vyoza',
  'vyubi',
  'vyuka',
  'vyuma',
  'vyuza',
  'wabwo',
  'wakwo',
  'wampa',
  'wamye',
  'wanje',
  'wanka',
  'wanke',
  'vyume',
  'wanse',
  'wanyu',
  'warwo',
  'waryo',
  'watse',
  'wavyo',
  'wibwe',
  'wimwa',
  'wimye',
  'wishe',
  'witwa',
  'wivye',
  'wompa',
  'wonka',
  'wonke',
  'wonse',
  'wonze',
  'wopfa',
  'wosha',
  'wumva',
  'wumve',
  'wumye',
  'wundi',
  'wunga',
  'wunge',
  'wunze',
  'yabwo',
  'yagwa',
  'yakwa',
  'yambi',
  'yambu',
  'yampa',
  'yamye',
  'yandi',
  'yanje',
  'yanka',
  'yanke',
  'yanse',
  'yanyu',
  'yapfa',
  'yarwo',
  'yarya',
  'yaryo',
  'yatsa',
  'yatse',
  'yavyo',
  'yemwe',
  'yezwa',
  'yicwa',
  'yicwe',
  'yimba',
  'yimbe',
  'yimwa',
  'yimye',
  'yindi',
  'yinga',
  'yishe',
  'yiswe',
  'yitwa',
  'yivye',
  'yogwa',
  'yompa',
  'yompi',
  'yonde',
  'yonka',
  'yonsa',
  'yonse',
  'yonze',
  'yopfa',
  'yorwa',
  'yorya',
  'yosha',
  'yozwe',
  'yumva',
  'yumve',
  'yumye',
  'yunga',
  'yunge',
  'yunze',
  'zabwo',
  'zagwa',
  'zanje',
  'zanyu',
  'zarwo',
  'zaryo',
  'zatse',
  'zatwo',
  'zavyo',
  'zicwa',
  'zicwe',
  'zigwa',
  'zigwe',
  'zimba',
  'zimbe',
  'zimpa',
  'zimpe',
  'zimwa',
  'zimwe',
  'zimya',
  'zimye',
  'zindi',
  'zinga',
  'zinge',
  'zinwe',
  'zirya',
  'zirye',
  'zishe',
  'ziswe',
  'zitwa',
  'zompi',
  'zonda',
  'zonde',
  'zonka',
  'zonke',
  'zonsa',
  'zonse',
  'zonye',
  'zonze',
  'zumva',
  'zumve',
  'zumya',
  'zumye',
  'zunga',
  'zunge',
  'zunza',
  'zunze',
  'sombe',
  'bakwe',
  'koshe',
  'icebe',
  'bukwe',
  'inube',
  'konse',
  'onkwa',
  'itugu',
  'kovya',
  'ihebe',
  'kosha',
  'kotse',
  'shoka',
  'shoke',
  'shuha',
  'impfu',
  'isomo',
  'yonke',
  'bonke',
  'bonze',
  'ihute',
  'kobwe',
  'konwe',
  'mpeke',
  'ronke',
  'wotse',
  'yotse',
  'botse',
  'bweru',
  'cotse',
  'hekwa',
  'nkome',
  'ibuke',
  'izuku',
  'mpeko',
  'nkowe',
  'nzuke',
  'ruswa',
  'sabwa',
  'ahica',
  'ankwa',
  'bwake',
  'bwoze',
  'ibuyi',
  'iduge',
  'inuze',
  'komba',
  'korwa',
  'kunde',
  'kwepa',
  'mbese',
  'mbike',
  'mvako',
  'nkase',
  'nkote',
  'nsoma',
  'ntoya',
  'omeka',
  'omoke',
  'orohe',
  'pfuha',
  'pfuka',
  'songe',
  'sunzu',
  'swata',
  'ubuka',
  'ubuti',
  'uduce',
  'uduse',
  'udute',
  'umuse',
  'uriwe',
  'utoye',
  'utubu',
  'uvuke',
  'agora',
  'agowe',
  'aguwe',
  'akiwe',
  'akote',
  'amake',
  'amana',
  'bibwe',
  'bikwe',
  'boste',
  'conde',
  'dutsi',
  'gomwe',
  'gonde',
  'gonga',
  'ibida',
  'ibino',
  'idute',
  'ihone',
  'ihore',
  'ikeze',
  'ikijo',
  'ikina',
  'ikino',
  'ikoye',
  'ikure',
  'imote',
  'indye',
  'irisa',
  'irote',
  'isibe',
  'isiza',
  'isobe',
  'ivoma',
  'kamba',
  'kanga',
  'konzi',
  'kubwo',
  'manzi',
  'mobwa',
  'mpita',
  'mvubu',
  'ndaya',
  'ndira',
  'ndohe',
  'ndose',
  'ndoye',
  'ngara',
  'niswe',
  'nsege',
  'nseke',
  'nsobe',
  'nsore',
  'nsoze',
  'ntoze',
  'ntuke',
  'nunwe',
  'nweko',
  'nzigo',
  'nzura',
  'pfuke',
  'ratwa',
  'ratwe',
  'remba',
  'rihwa',
  'samba',
  'sikwo',
  'somba',
  'somwa',
  'tambe',
  'tesha',
  'torwe',
  'tukwe',
  'turwe',
  'twake',
  'tware',
  'ubufi',
  'ucuke',
  'ufuse',
  'ugoje',
  'ugowe',
  'uhiga',
  'uhira',
  'ukeze',
  'ukowe',
  'ukoye',
  'umubo',
  'umute',
  'uruka',
  'urura',
  'urute',
  'usare',
  'useke',
  'usobe',
  'usose',
  'utima',
  'utira',
  'utowe',
  'utoze',
  'utune',
  'uvure',
  'uzobe',
  'vamwo',
  'vyuke',
  'wezwa',
  'wiswe',
  'wonde',
  'worya',
  'yezwe',
  'yokwa',
  'yonye',
  'zotse',
  'agaki',
  'ninta',
  'akavi',
  'nibwa',
  'icapa',
  'ntira',
  'ninja',
  'ninza',
  'nisha',
  'sinwa',
  'arata',
  'hinda',
  'nanzi',
  'higwa',
  'ninca',
  'ubazi',
  'gwana',
  'akagi',
  'itati',
  'ivuta',
  'ivura',
  'iziba',
  'abezi',
  'isaka',
  'ntiva',
  'canzo',
  'igise',
  'ikena',
  'ringa',
  'tumwa',
  'utiba',
  'abiga',
  'ihuma',
  'ihura',
  'ijane',
  'munda',
  'ndaka',
  'ntazo',
  'nyota',
  'ryana',
  'sango',
  'wimba',
  'ibuza',
  'ikura',
  'imare',
  'ndake',
  'ntisa',
  'uyazi',
  'bwina',
  'ibana',
  'ibare',
  'ibito',
  'ikuzo',
  'imifa',
  'isemo',
  'itana',
  'ituna',
  'kange',
  'kwisa',
  'ndare',
  'nsita',
  'sinda',
  'ujeko',
  'utica',
  'ahabi',
  'ahato',
  'atica',
  'atira',
  'binta',
  'bitwi',
  'cinya',
  'gisha',
  'icaga',
  'imata',
  'iruha',
  'itema',
  'mbata',
  'ncako',
  'ndasa',
  'ntaha',
  'ntica',
  'ntiga',
  'nyiza',
  'rweze',
  'twiba',
  'twica',
  'ukine',
  'uruja',
  'zibwa',
  'agita',
  'agore',
  'ariba',
  'arika',
  'ashwi',
  'atiba',
  'atiza',
  'bitya',
  'fundi',
  'himba',
  'icona',
  'ijeke',
  'ikaki',
  'imbga',
  'imesa',
  'imibe',
  'inege',
  'iraso',
  'kamwa',
  'manga',
  'ndaye',
  'nibwe',
  'nyiga',
  'nzama',
  'nzozi',
  'simva',
  'sitwo',
  'urobe',
  'uruzu',
  'utiga',
  'uwacu',
  'vumba',
  'wezwe',
  'zirwa',
  'akiga',
  'atahe',
  'atije',
  'bitsa',
  'bunwa',
  'bwiga',
  'gikwa',
  'hazwi',
  'icaka',
  'ipata',
  'irara',
  'irata',
  'ireba',
  'irofa',
  'itesa',
  'itiba',
  'itica',
  'itiha',
  'itira',
  'itiza',
  'itoba',
  'ituka',
  'ivuma',
  'iyahe',
  'izima',
  'kavya',
  'kicwa',
  'kimba',
  'mpana',
  'mpime',
  'murwi',
  'nashe',
  'natse',
  'ncane',
  'ndisa',
  'nivye',
  'nonze',
  'nsaze',
  'ntire',
  'nyaga',
  'omora',
  'ronge',
  'runda',
  'rwera',
  'rwiga',
  'rwoge',
  'twaza',
  'ugama',
  'ukuze',
  'umata',
  'umene',
  'urata',
  'uriba',
  'uriha',
  'usiza',
  'uyita',
  'uziga',
  'uziha',
  'uzira',
  'zanka',
  'zinya',
  'abike',
  'abima',
  'abime',
  'abisa',
  'aboza',
  'aboze',
  'abuce',
  'abute',
  'acapa',
  'agaka',
  'agaye',
  'aghnj',
  'agice',
  'agite',
  'aguka',
  'ahace',
  'ahacu',
  'ahata',
  'ahate',
  'ahije',
  'ajate',
  'akica',
  'akige',
  'akobo',
  'akome',
  'amire',
  'amoke',
  'andya',
  'arate',
  'areze',
  'arisa',
  'asaza',
  'asaze',
  'asige',
  'ateko',
  'atiga',
  'atire',
  'atuye',
  'atuze',
  'avaho',
  'avuke',
  'avume',
  'avute',
  'ayabo',
  'ayaki',
  'azayo',
  'azige',
  'azita',
  'aziya',
  'banke',
  'batse',
  'bityo',
  'bwoza',
  'canse',
  'dahwa',
  'ekaha',
  'hamba',
  'hanse',
  'igika',
  'ihaha',
  'ihoma',
  'ihora',
  'ihoza',
  'ikire',
  'ikita',
  'ikopo',
  'imoye',
  'iruke',
  'itowe',
  'itoza',
  'kamye',
  'kimpa',
  'mbahe',
  'mbaze',
  'mbege',
  'mbome',
  'mvome',
  'mvuza',
  'ndate',
  'ndera',
  'ndika',
  'ndote',
  'nezwe',
  'ngabe',
  'ngeza',
  'nkame',
  'nkene',
  'nkica',
  'nkiga',
  'nkuza',
  'nsama',
  'nseka',
  'nsema',
  'nsiba',
  'nteza',
  'nyabo',
  'nyaka',
  'nzaha',
  'nziga',
  'nzuki',
  'ryige',
  'twacu',
  'twaka',
  'twoga',
  'twoza',
  'ubake',
  'uhaba',
  'uhora',
  'uhoza',
  'ukiba',
  'urema',
  'ureme',
  'ureze',
  'urica',
  'uruha',
  'uruma',
  'uruza',
  'uzura',
  'vange',
  'vumbi',
  'vyisa',
  'witwe',
  'wogwa',
  'yirye',
  'zibwe',
  'zopfa',
  'amohe',
  'akahe',
  'amuce',
  'akawe',
  'amube',
  'amute',
  'atose',
  'akase',
  'tasha',
  'agacu',
  'agato',
  'syera',
  'amuve',
  'amure',
  'atowe',
  'amura',
  'atobe',
  'hasha',
  'mwere',
  'agate',
  'akare',
  'akabe',
  'atone',
  'atoje',
  'atote',
  'akavu',
  'imura',
  'gapfa',
  'vyene',
  'atohe',
  'gatwa',
  'casha',
  'akayo',
  'karwa',
  'ahaje',
  'ngene',
  'akami',
  'ayaje',
  'aroye',
  'gatya',
  'imoka',
  'akave',
  'nyeze',
  'araho',
  'washa',
  'afoye',
  'atoke',
  'yasha',
  'anume',
  'ekaye',
  'nzeye',
  'hwera',
  'akoye',
  'amatu',
  'impfa',
  'sagwa',
  'ndehe',
  'akahi',
  'karye',
  'akaro',
  'bwene',
  'okera',
  'ryeza',
  'kasha',
  'gabwa',
  'njeje',
  'araye',
  'nsehe',
  'zasha',
  'asase',
  'ndege',
  'asume',
  'uhera',
  'ndeze',
  'amote',
  'arote',
  'ndeme',
  'kumva',
  'rabwa',
  'mwege',
  'akake',
  'akavo',
  'amose',
  'njebe',
  'adode',
  'atuje',
  'akajo',
  'akera',
  'ikare',
  'korya',
  'ogera',
  'ryere',
  'mpebe',
  'nkeye',
  'nurya',
  'ravya',
  'vyere',
  'asame',
  'ayote',
  'amene',
  'mwake',
  'ragwa',
  'ahazi',
  'ngese',
  'nteke',
  'rweza',
  'rwira',
  'afute',
  'bampe',
  'randa',
  'adohe',
  'umuta',
  'ahore',
  'asose',
  'atiye',
  'masha',
  'twene',
  'acape',
  'mwone',
  'rwara',
  'ryene',
  'afuse',
  'akabu',
  'akada',
  'ntege',
  'ahuye',
  'isara',
  'kagwe',
  'kande',
  'rwasa',
  'sarwa',
  'abene',
  'akeze',
  'ature',
  'nkeze',
  'yampe',
  'abisi',
  'imida',
  'aguze',
  'akoya',
  'ayate',
  'rwaga',
  'abaki',
  'ayoye',
  'ehera',
  'ngere',
  'sanza',
  'asobe',
  'avura',
  'erera',
  'ganya',
  'harwa',
  'ibari',
  'rusya',
  'ryeze',
  'vyema',
  'anura',
  'ayore',
  'hasya',
  'igara',
  'ihara',
  'karwe',
  'igare',
  'ukene',
  'ahume',
  'gunda',
  'hatsa',
  'akowe',
  'ibaza',
  'iyeze',
  'kanwe',
  'narya',
  'ngeme',
  'otera',
  'rubwa',
  'urame',
  'akose',
  'asara',
  'ateka',
  'ayoge',
  'ikapo',
  'kesha',
  'ampfe',
  'atege',
  'husha',
  'imija',
  'iraha',
  'njeme',
  'akene',
  'akeye',
  'arame',
  'avure',
  'carya',
  'gorwa',
  'kambe',
  'kampa',
  'kezwa',
  'mpeme',
  'nwere',
  'raswa',
  'vunja',
  'abohe',
  'acuye',
  'ajobe',
  'ayage',
  'batwa',
  'dusya',
  'kanye',
  'kapfa',
  'nteme',
  'vyege',
  'atune',
  'avune',
  'karwi',
  'rambe',
  'ryame',
  'warya',
  'adata',
  'arobe',
  'asoze',
  'ataco',
  'ayoje',
  'habwa',
  'honga',
  'mpere',
  'nseme',
  'vyame',
  'ibaro',
  'kotsa',
  'akuzi',
  'kamve',
  'mpave',
  'murwa',
  'nemye',
  'rwaka',
  'rwere',
  'shami',
  'sumba',
  'turwa',
  'ukame',
  'adasa',
  'aduce',
  'akuka',
  'amesa',
  'aroze',
  'aruhe',
  'atoza',
  'atuke',
  'ibaya',
  'kweze',
  'mpane',
  'rupfa',
  'rwene',
  'tunwe',
  'anobe',
  'atawe',
  'bwere',
  'enene',
  'ndese',
  'ngera',
  'nyora',
  'tweze',
  'twika',
  'vyega',
  'wande',
  'agahe',
  'ahabe',
  'ahoje',
  'arema',
  'atayo',
  'avuse',
  'gotwa',
  'igura',
  'katsa',
  'kwame',
  'nseze',
  'nyara',
  'ugata',
  'ukate',
  'ukera',
  'arure',
  'arute',
  'asuma',
  'atoga',
  'ayoze',
  'bakwa',
  'curwa',
  'mwuka',
  'nyoza',
  'sheta',
  'tuvya',
  'acoke',
  'afuhe',
  'aguce',
  'agute',
  'ahaga',
  'ahome',
  'akata',
  'asope',
  'atete',
  'ayace',
  'ayaze',
  'ayeze',
  'ayora',
  'emeze',
  'gapfe',
  'hanwa',
  'mahwi',
  'mpage',
  'mwavu',
  'ribwa',
  'uname',
  'urasa',
  'usame',
  'utuzu',
  'vyone',
  'warwa',
  'agare',
  'amuba',
  'arayi',
  'arore',
  'aruce',
  'asure',
  'avoma',
  'eyewe',
  'funya',
  'gatyo',
  'icuki',
  'mpone',
  'mwote',
  'nyoye',
  'tunya',
  'ugora',
  'uvura',
  'vyare',
  'abore',
  'agona',
  'aseha',
  'atesa',
  'awute',
  'batya',
  'bazwa',
  'gwire',
  'hampe',
  'irava',
  'mbane',
  'narwa',
  'nkate',
  'nkese',
  'runwa',
  'rutya',
  'rwega',
  'ubira',
  'usara',
  'usema',
  'utiza',
  'afite',
  'anoze',
  'anuke',
  'apape',
  'avuwe',
  'awoze',
  'awuce',
  'gwacu',
  'igoma',
  'igora',
  'inera',
  'isogo',
  'ituze',
  'manze',
  'mbage',
  'mpaze',
  'mpiga',
  'mugwa',
  'muswa',
  'mwoye',
  'nesha',
  'ngone',
  'rurwe',
  'tugwa',
  'tyoza',
  'ugaba',
  'uhena',
  'ukabe',
  'ukata',
  'umate',
  'umese',
  'ureba',
  'uriyo',
  'acune',
  'acuwe',
  'adute',
  'ahahe',
  'ahana',
  'ahasa',
  'ahene',
  'ahere',
  'anoye',
  'asuze',
  'aveyo',
  'avuma',
  'awoge',
  'azote',
  'gashe',
  'govya',
  'hopfa',
  'imuke',
  'irasa',
  'ireme',
  'irira',
  'isoma',
  'mpaya',
  'musya',
  'myira',
  'natsa',
  'ndeha',
  'nebwe',
  'nimwe',
  'nkeme',
  'nsibe',
  'ntako',
  'nyuze',
  'rugwi',
  'rumwa',
  'ryita',
  'swaga',
  'twere',
  'ubuca',
  'ucane',
  'ugabe',
  'ugema',
  'uhore',
  'usuka',
  'utone',
  'utuvi',
  'uvome',
  'vyuya',
  'yovya',
  'zarya',
  'abeze',
  'aboye',
  'afuta',
  'agone',
  'ahabo',
  'akaka',
  'amako',
  'aroge',
  'ayazi',
  'cibwa',
  'ganze',
  'gaywa',
  'gwawe',
  'gwuba',
  'hambe',
  'hinde',
  'hosha',
  'ikira',
  'imate',
  'iruma',
  'isoda',
  'itiyo',
  'itura',
  'iyaje',
  'kebwa',
  'konze',
  'kozwa',
  'mbura',
  'mfuta',
  'mpese',
  'mumpa',
  'mutya',
  'mweza',
  'mwiba',
  'mwite',
  'napfa',
  'ndega',
  'ngema',
  'ngore',
  'nkoye',
  'nkuba',
  'ntego',
  'runya',
  'rurwa',
  'rwaje',
  'rwaza',
  'rwima',
  'ryasa',
  'ryota',
  'shake',
  'tabwa',
  'twume',
  'udusa',
  'ugare',
  'uhame',
  'uhave',
  'uheka',
  'uheme',
  'umudo',
  'urisa',
  'uyora',
  'vyake',
  'vyoya',
  'watsa',
  'yarye',
  'abise',
  'abome',
  'acayo',
  'acore',
  'acume',
  'adume',
  'ageme',
  'ahaza',
  'ahema',
  'akibe',
  'akice',
  'akije',
  'akone',
  'akota',
  'akuga',
  'aseka',
  'asura',
  'atane',
  'atosa',
  'atota',
  'atuza',
  'avobe',
  'azave',
  'azoje',
  'azuke',
  'azure',
  'bamwa',
  'bempi',
  'bunda',
  'canda',
  'cande',
  'egeze',
  'gambe',
  'gapfu"',
  'giswi',
  'gogwa',
  'hirwe',
  'honya',
  'ibeba',
  'ibore',
  'icica',
  'igena',
  'igipe',
  'igure',
  'iguvu',
  'ihoro',
  'imure',
  'iribi',
  'irizi',
  'irota',
  'isera',
  'isiga',
  'isuma',
  'iyeza',
  'iyuva',
  'kwize',
  'marya',
  'mashi',
  'mfuka',
  'mpoza',
  'mveho',
  'mwibe',
  'mwoje',
  'mwuga',
  'ndoze',
  'nduhe',
  'ngaru',
  'ngemu',
  'ngona',
  'ngoye',
  'nguye',
  'njeho',
  'nkare',
  'nsaza',
  'nsega',
  'nsige',
  'nurye',
  'nyoku',
  'nzoce',
  'pfira',
  'rirye',
  'rotsa',
  'rukwi',
  'rwake',
  'tunge',
  'ugace',
  'ugate',
  'ugena',
  'ugone',
  'uhaje',
  'ukena',
  'ukeye',
  'ukoga',
  'uraye',
  'uroge',
  'uroye',
  'utame',
  'utega',
  'utose',
  'utuda',
  'utuna',
  'uvayo',
  'uvune',
  'uwisa',
  'wibwa',
  'abite',
  'acoge',
  'acota',
  'adaca',
  'agawe',
  'ahame',
  'aheme',
  'ahese',
  'ahoga',
  'akiba',
  'akiha',
  'anoge',
  'arica',
  'arije',
  'arike',
  'asuwe',
  'asuye',
  'atazo',
  'avuna',
  'ayaja',
  'ayaka',
  'ayice',
  'ayise',
  'ayoca',
  'uhona',
  'uboha',
  'urona',
  'uvuna',
  'usoba',
  'uwoca',
  'uwoja',
  'utoja',
  'ndoza',
  'nkaha',
  'ntuja',
  'usesa',
  'uwosa',
  'bashe',
  'batsa',
  'batyo',
  'habwe',
  'hamya',
  'yanwe',
  'inige',
  'tange',
  'iciwe',
  'cange',
  'nasye',
  'ahane',
  'canze',
  'icahe',
  'ariyo',
  'igane',
  'igine',
  'ijojo',
  'ikame',
  'ikizu',
  'ipafu',
  'isige',
  'iyayo',
  'avahe',
  'iyawe',
  'ajahe',
  'siryo',
  'nkiyo',
  'uvuka',
  'isose',
  'iyaza',
  'mpame',
  'nanze',
  'ndihe',
  'ndora',
  'nduha',
  'ndura',
  'ngane',
  'nkima',
  'nkuze',
  'nsize',
  'nyare',
  'nyita',
  'rwane',
  'sagwe',
  'twugi',
  'ubuso',
  'ubuvi',
  'ucape',
  'ugese',
  'uhace',
  'uhane',
  'uhere',
  'uhese',
  'uheza',
  'ujayo',
  'ujebe',
  'ukeka',
  'umesa',
  'unume',
  'urate',
  'uraze',
  'urebe',
  'urore',
  'urose',
  'useka',
  'usige',
  'utaye',
  'uteme',
  'utize',
  'utuka',
  'utura',
  'uwayo',
  'uwazo',
  'uwona',
  'uwoza',
  'vyahe',
  'vyige',
  'vyota',
  'wemye',
  'wenge',
  'abibe',
  'abire',
  'aboga',
  'agese',
  'agose',
  'ahire',
  'ahisa',
  'ahise',
  'ahite',
  'ahoma',
  'ahona',
  'ahuma',
  'ajeho',
  'anige',
  'arohe',
  'arona',
  'arora',
  'atabe',
  'ataca',
  'ateyo',
  'ayata',
  'ayayo',
  'ayoba',
  'ayoha',
  'ayota',
  'ayoza',
  'azako',
  'azohe',
  'azosa',
  'azuye',
  'bemye',
  'bompa',
  'borya',
  'bugya',
  'bumpa',
  'bupfa',
  'bwime',
  'bwine',
  'camwo',
  'cogwa',
  'dahwe',
  'damwa',
  'harwe',
  'hasta',
  'hatse',
  'hovyo',
  'iceze',
  'ifuta',
  'iheza',
  'ihume',
  'ihutu',
  'ijagi',
  'ipaki',
  'iperu',
  'ipime',
  'isoba',
  'itoni',
  'ivayo',
  'kibwe',
  'kumye',
  'mbana',
  'mfore',
  'mfoye',
  'mfuse',
  'mfute',
  'mpabe',
  'mpare',
  'mpava',
  'mpige',
  'mpiri',
  'mpura',
  'mpure',
  'mpuye',
  'munte',
  'munye',
  'muvyo',
  'mveko',
  'mvure',
  'mvuzo',
  'mwuma',
  'mwume',
  'nabwo',
  'nceyo',
  'ndeba',
  'ndise',
  'ndiyo',
  'ndode',
  'nduma',
  'nduta',
  'nerwe',
  'ngare',
  'ngohe',
  'nimbe',
  'nimye',
  'nkira',
  'nkoza',
  'nonde',
  'nonka',
  'norwa',
  'nsara',
  'nsopa',
  'nsura',
  'ntave',
  'ntaye',
  'nteba',
  'ntusa',
  'onkwe',
  'rumpa',
  'rushe',
  'ruzwi',
  'rwota',
  'rwoya',
  'rwoza',
  'ryiba',
  'tashe',
  'tinga',
  'ubayo',
  'ubege',
  'ubora',
  'ubore',
  'ubuda',
  'uduke',
  'uhava',
  'uhaya',
  'uhazi',
  'uheba',
  'ujako',
  'ujeba',
  'ukema',
  'ukeme',
  'ukeza',
  'ukoma',
  'ukona',
  'ukone',
  'ukoza',
  'umote',
  'urage',
  'usiba',
  'utabe',
  'utaja',
  'utava',
  'utobe',
  'utoga',
  'uveyo',
  'uvira',
  'uweza',
  'uwute',
  'uzama',
  'uziko',
  'vomwa',
  'vyure',
  'wakwa',
  'watwe',
  'wicwa',
  'wonsa',
  'worwa',
  'yanda',
  'yibwe',
  'zanda',
  'abawo',
  'adoha',
  'anuze',
  'asopa',
  'ateho',
  'ayizi',
  'ayoga',
  'azizi',
  'banze',
  'bwasa',
  'bwaza',
  'bwoga',
  'campa',
  'conse',
  'conye',
  'copfa',
  'corya',
  'cosha',
  'cosya',
  'fyigo',
  'gahwi',
  'iriga',
  'iriha',
  'isuke',
  'utiha',
  'vyite',
  'vyoye',
]
