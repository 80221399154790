export type CountryKey = keyof typeof COUNTRIES

export const COUNTRIES = {
  'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
  'Burkina Faso': 'Burkina Faso',
  'Cabo Verde': 'Cabo Verde',
  'Central African Republic': 'Central African Republic',
  'Dominican Republic': 'Dominican Republic',
  'DR Congo': 'Repuburika iharanira Demokarasi ya Kongo',
  'Hong Kong': 'Hong Kong',
  'Ivory Coast': 'Ivory Cast',
  'São Tomé and Príncipe': 'São Tomé and Príncipe',
  'South Africa': "Africa y'epfo",
  'South Korea': "Koreya y'epfo",
  'South Sudan': "Sudani y'epfo",
  'Sri Lanka': 'Sri Lanka',
  'United Arab Emirates': 'United Arab Emirates',
  'United Kingdom': 'Ubwongereza',
  'United States': 'Reta Zunze Ubumwe za Amerika',
  Australia: 'Australia',
  Austria: 'Austria',
  Belarus: 'Belarus',
  Belgium: 'Ububirigi',
  Benin: 'Benin',
  Botswana: 'Botswana',
  Brazil: 'Brazil',
  Bulgaria: 'Bulgaria',
  Burundi: 'Uburundi',
  Cameroon: 'Kamerune',
  Canada: 'Kanada',
  Chad: 'Tchad',
  China: 'Ubushinwa',
  Croatia: 'Croatia',
  Cuba: 'Kuba',
  Cyprus: 'Cyprus',
  Czechia: 'Czechia',
  Denmark: 'Denmark',
  Egypt: 'Misiri',
  Eritrea: 'Eritrea',
  Estonia: 'Estonia',
  Eswatini: 'Eswatini',
  Ethiopia: 'Ethiopia',
  Finland: 'Finland',
  France: 'Ubufaransa',
  Gabon: 'Gabon',
  Gambia: 'Gambia',
  Germany: 'Ubudagi',
  Ghana: 'Ghana',
  Guadeloupe: 'Guadeloupe',
  Guinea: 'Guinea',
  Haiti: 'Haiti',
  India: 'Ubuhindi',
  Indonesia: 'Indonesia',
  Ireland: 'Ireland',
  Israel: 'Israyeri',
  Italy: 'Ubutariyano',
  Jamaica: 'Jamayika',
  Japan: 'Ubuyapani',
  Kazakhstan: 'Kazakhstan',
  Kenya: 'Kenya',
  Latvia: 'Latvia',
  Lebanon: 'Lebanon',
  Libya: 'Libya',
  Lithuania: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Madagascar: 'Madagascar',
  Malawi: 'Malawi',
  Malaysia: 'Malaysia',
  Mali: 'Mali',
  Malta: 'Malta',
  Mauritius: 'Mauritius',
  Mexico: 'Mexico',
  Moldova: 'Moldova',
  Mozambique: 'Mozambique',
  Namibia: 'Namibia',
  Netherlands: 'Ubuhorande',
  Niger: 'Niger',
  Nigeria: 'Nigeria',
  Norway: 'Norway',
  Oman: 'Omani',
  Poland: 'Poland',
  Portugal: 'Portugal',
  Qatar: 'Katare',
  Réunion: 'Réunion',
  Romania: 'Romania',
  Russia: 'Uburusiya',
  Rwanda: 'Urwanda',
  Senegal: 'Senegal',
  Serbia: 'Serbia',
  Singapore: 'Singapore',
  Slovakia: 'Slovakia',
  Slovenia: 'Slovenia',
  Somalia: 'Somalia',
  Spain: 'Spain',
  Sudan: 'Sudan',
  Sweden: 'Ubusuwede',
  Switzerland: 'Swise',
  Taiwan: 'Tayiwani',
  Tanzania: 'Tanzaniya',
  Thailand: 'Thailand',
  Togo: 'Togo',
  Turkey: 'Turukiya',
  Uganda: 'Ubuganda',
  Zambia: 'Zambiya',
  Zimbabwe: 'Zimbabwe',
}
